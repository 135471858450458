import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import "./RequestMeetupModal.css";
import dayjs from "dayjs";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TimePicker } from 'formik-material-ui-pickers';
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
  Media,
  Nav,
  Tab,
  InputGroup,
  FormControl,
  FormLabel,
} from "react-bootstrap";

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import {
  fetchMeetupCategoryListStart,
  meetupRequestStart
} from "../../store/actions/ScheduleMeetupAction";

import moment from 'moment';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));


var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

const RequestMeetupModal = (props) => {
  console.log('props', props)

  const nullData = ["", null, undefined, "light"];

  const classes = useStyles();

  const formRef = useRef();


  const validationSchema = Yup.object().shape({
    title: Yup.string().when("category_id", {
      is: (category_id) => category_id == 7,
      then: Yup.string().required('Title is required')
    }),
    location: Yup.string().required("Location is required")
  });


  useEffect(() => {

    props.dispatch(fetchMeetupCategoryListStart());

    formRef.current.setFieldValue("category_id", 1);
    formRef.current.setFieldValue("influencer_id", props.userDetails.data.user.user_id);
    formRef.current.setFieldValue("location", '');
    formRef.current.setFieldValue("requested_at", new Date());
  }, []);

  const handleClose = () => {
    props.closeRequestMeetupModal();
  }

  const onSubmit = (value) => {

    let requestedData = { ...value };

    if(requestedData.category_id != 7){
      requestedData.title = "";
    }

    requestedData.requested_at = moment(requestedData.requested_at).format('YYYY-MM-DD HH:mm:ss');
    props.dispatch(meetupRequestStart(requestedData));
  }


  const CategoryOption = ({ category }) => {

    let hoursText = '';
    if (category.min_hours === category.max_hours) {
      if (category.min_hours == 24) {
        hoursText = ` (1 Day)`;
      } else if (category.min_hours > 0) {
        if (category.min_hours > 1) {
          hoursText = ` (${category.min_hours} Hours)`;
        } else {
          hoursText = ` (${category.min_hours} Hour)`;
        }

      }
    } else {
      let minSuffix = '';
      let maxSuffix = '';
      if (category.min_hours == 24) {
        minSuffix = `1 Day`;
      } else if (category.min_hours > 0) {
        if (category.min_hours > 1) {
          minSuffix = `${category.min_hours} Hours`;
        } else {
          minSuffix = `${category.min_hours} Hour`;
        }
      }
      if (category.max_hours == 24) {
        maxSuffix = `1 Day`;
      } else if (category.max_hours > 0) {
        if (category.max_hours > 1) {
          maxSuffix = `${category.max_hours} Hours`;
        } else {
          maxSuffix = `${category.max_hours} Hour`;
        }
      }

      if (minSuffix && maxSuffix) {

        hoursText = ` (${minSuffix} - ${maxSuffix})`;
      }
    }




    return (
      <option value={category.id}>
        {category.name}
        {hoursText}
      </option>
    );
  };


  const CategorySelect = ({ categoryList, loading, t }) => {
    if (loading) {
      return <option>{t('loading')}</option>;
    }

    return (
      <>
        {categoryList.data.map((category) => (
          <CategoryOption key={category.id} category={category} />
        ))}
      </>
    );
  };

  return (
    <>
      <Modal
        className={`modal-dialog-center sent-tip-modal
        ${nullData.includes(localStorage.getItem("theme")) ?
            "" : "dark-theme-modal"
          }
        `}
        size="md"
        centered
        show={true}
        onHide={handleClose}
      >

        <div className="star-modal-header">
          <h3>Request Meet up</h3>
          <Button className="star-modal-close" onClick={() => handleClose()}>
            <Image
              className="close-icon"
              src={
                window.location.origin + "/assets/images/schedule-meetup/close-white.svg"
              }
            />
          </Button>

        </div>


        <div className="star-user-card margin-left-right">
          <div className="star-user-img-sec">
            <Image
              className="star-user-img"
              src={props.userDetails.data.user.picture}
              alt={props.userDetails.data.user.name}
            />
          </div>
          <div className="star-user-details">
            <h5>{props.userDetails.data.user.name}</h5>
            <span className="user-tag">@{props.userDetails.data.user.unique_id}</span>
          </div>
        </div>

        <div className="form-wrapper">

          <Formik
            innerRef={formRef}
            initialValues={{
              category_id: 1,
              requested_at: new Date(),
              location: '',
              title: ''
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({ values, errors, touched }) => (
              <FORM className="edit-profile-form" noValidate>
                <Row>
                  <Col md={12}>

                    <Form.Group controlId="formBasicEmail1">
                      <Field
                        as="select"
                        name="category_id"
                        className="form-control"
                      >
                        <CategorySelect categoryList={props.categoryList} loading={props.categoryList.loading} t={t} />
                      </Field>
                    </Form.Group>
                  </Col>

                  {
                    (values.category_id == 7) ?

                      <Col md={12}>

                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Title</Form.Label>
                          <Form.Text className="text-muted">
                            Enter your Meet up title
                          </Form.Text>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder="Title"
                            name="title"
                            autoFocus={true}
                          />
                          <ErrorMessage
                            name="title"
                            component="div"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                      </Col>

                      : ""
                  }
                </Row>
                <Row>

                  <Col md={12}>

                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Location</Form.Label>
                      <Form.Text className="text-muted">
                        Enter the meet up location
                      </Form.Text>
                      <Field
                        className="form-control"
                        type="text"
                        placeholder="Location"
                        name="location"
                      />
                      <ErrorMessage
                        name="location"
                        component="div"
                        className="text-danger text-right"
                      />
                    </Form.Group>
                  </Col>



                </Row>

                <Row>
                  <Col md={12}>
                    <Form.Label>Date/Time</Form.Label>
                    <Form.Text className="text-muted">
                      Select time of Meet up
                    </Form.Text>
                  </Col>

                  <Col md={12}>
                    <Field
                      className="themeTimePicker"
                      name="requested_at"
                      ampm="false"
                      openTo="hours"
                      views={['hours', 'minutes', 'seconds']}
                      type="datetime-local"
                      format="HH:mm:ss"
                    />
                  </Col>
                </Row>




                <Row>
                  <Col md={12}>
                    <div className="settings-btn-sec">

                      <Button
                        type="submit"
                        className="settings-submit-btn"
                        disabled={
                          props.meetupRequestSave.buttonDisable
                        }
                      >
                        {props.meetupRequestSave.loadingButtonContent
                          ? props.meetupRequestSave.loadingButtonContent
                          : ' Request Meet up'
                        }
                      </Button>

                    </div>
                  </Col>
                </Row>



              </FORM>
            )}
          </Formik>

        </div>

      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  categoryList: state.scheduleMeetup.categoryList,
  meetupRequestSave: state.scheduleMeetup.meetupRequestSave
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(RequestMeetupModal));
