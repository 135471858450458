import React, { useEffect, useState, useRef } from "react";
import ScheduleMeetupkNav from "./ScheduleMeetupNav";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TimePicker } from 'formik-material-ui-pickers';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import {
  fetchMeetupCategoryListStart,
  addMeetupScheduleStart
} from "../../store/actions/ScheduleMeetupAction";

import moment from 'moment';
import configuration from "react-global-configuration";

const ScheduleMeetupAdd = (props) => {

  const formRef = useRef();

  const validationSchema = Yup.object().shape({
    title: Yup.string().when("category_id", {
      is: (category_id) => category_id == 7,
      then: Yup.string().required(t("title_is_required"))
    }),
    amount: Yup.number()
      .required(t("amount_is_required"))
      .min(1, t("amount_cannot_be_less_than_1")),
    location: Yup.string().required(t("location_is_required"))
  });


  useEffect(() => {

    props.dispatch(fetchMeetupCategoryListStart());

    formRef.current.setFieldValue("category_id", 1);
    formRef.current.setFieldValue("title", '');
    formRef.current.setFieldValue("type", 'Fixed');
    formRef.current.setFieldValue("amount", 0);
    formRef.current.setFieldValue("bid_closed_at", new Date());
    formRef.current.setFieldValue("privacy", 'Everyone');
    formRef.current.setFieldValue("location", '');
    formRef.current.setFieldValue("date", new Date());
    formRef.current.setFieldValue("scheduled_at", new Date());
    formRef.current.setFieldValue("scheduled_end_at", new Date());
  }, []);



  const onSubmit = (value) => {

    let requestedData = { ...value };
    requestedData.date = moment(requestedData.date).format('YYYY-MM-DD')
    let startTime = moment(requestedData.scheduled_at).format('HH:mm:ss');
    let endTime = moment(requestedData.scheduled_end_at).format('HH:mm:ss');
    requestedData.scheduled_at = `${requestedData.date} ${startTime}`;
    requestedData.scheduled_end_at = `${requestedData.date} ${endTime}`;

    if (requestedData.type == 'Bid') {
      requestedData.bid_closed_at = moment(requestedData.bid_closed_at).format('YYYY-MM-DD HH:mm:ss')
    } else {
      delete requestedData.bid_closed_at;
    }

    if (requestedData.category_id != 7) {
      requestedData.title = "";
    }

    props.dispatch(addMeetupScheduleStart(requestedData));
  }


  const CategoryOption = ({ category }) => {

    let hoursText = '';
    if (category.min_hours === category.max_hours) {
      if (category.min_hours == 24) {
        hoursText = ` (1 Day)`;
      } else if (category.min_hours > 0) {
        if (category.min_hours > 1) {
          hoursText = ` (${category.min_hours} ${t('hours')})`;
        } else {
          hoursText = ` (${category.min_hours} ${t('hour')})`;
        }

      }
    } else {
      let minSuffix = '';
      let maxSuffix = '';
      if (category.min_hours == 24) {
        minSuffix = `1 Day`;
      } else if (category.min_hours > 0) {
        if (category.min_hours > 1) {
          minSuffix = `${category.min_hours} ${t('hours')}`;
        } else {
          minSuffix = `${category.min_hours} ${t('hour')}`;
        }
      }
      if (category.max_hours == 24) {
        maxSuffix = `1 Day`;
      } else if (category.max_hours > 0) {
        if (category.max_hours > 1) {
          maxSuffix = `${category.max_hours} ${t('hours')}`;
        } else {
          maxSuffix = `${category.max_hours} ${t('hour')}`;
        }
      }

      if (minSuffix && maxSuffix) {

        hoursText = ` (${minSuffix} - ${maxSuffix})`;
      }
    }


    return (
      <option value={category.id}>
        {category.name}
        {hoursText}
      </option>
    );
  };


  const CategorySelect = ({ categoryList, loading, t }) => {
    if (loading) {
      return <option>{t('loading')}</option>;
    }

    return (
      <>
        {categoryList.data.map((category) => (
          <CategoryOption key={category.id} category={category} />
        ))}
      </>
    );
  };


  return (
    <>
      <div className="edit-profile bookmark-sec">
        <Container>
          <Row>
            <ScheduleMeetupkNav />
            <Col sm={12} xs={12} md={8}>
              <div className="profile-post-area">
                <div className="bookmarkes-list bookmarks-right-side">
                  <div className="pull-left text-dark">
                    <h3>{t('schedule_meetup')}</h3>
                  </div>
                </div>
              </div>


              <div className="settings-personal-info-body">
                <Formik
                  innerRef={formRef}
                  initialValues={{
                    category_id: 1,
                    title: "",
                    type: "",
                    amount: 0,
                    location: "",
                    bid_closed_at: new Date(),
                    privacy: "Everyone",
                    date: new Date(),
                    scheduled_at: new Date(),
                    scheduled_end_at: new Date(),
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    onSubmit(values);
                  }}
                >
                  {({ values, errors, touched, formik }) => (
                    <FORM className="edit-profile-form" noValidate>
                      <Row>
                        <Col md={12}>

                          <Form.Group controlId="formBasicEmail1">
                            <Field
                              as="select"
                              name="category_id"
                              className="form-control"
                            >
                              <CategorySelect categoryList={props.categoryList} loading={props.categoryList.loading} t={t} />
                            </Field>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        {
                          (values.category_id == 7) ?

                            <Col md={6}>

                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>{t('title')}</Form.Label>
                                <Form.Text className="text-muted">
                                  {t('enter_your_meet_up_title')}
                                </Form.Text>
                                <Field
                                  className="form-control"
                                  type="text"
                                  placeholder={t('title')}
                                  name="title"
                                  autoFocus={true}
                                />
                                <ErrorMessage
                                  name="title"
                                  component="div"
                                  className="text-danger text-right"
                                />
                              </Form.Group>
                            </Col>

                            : ""
                        }

                        <Col md={(values.category_id == 7 ? 6 : 12)}>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>{t('pricing_type')}</Form.Label>
                            <Form.Text className="text-muted">
                              {t('choose_pricing_type')}
                            </Form.Text>

                            <div className="radioGroup">
                              <label>
                                <Field
                                  className="form-control"
                                  type="radio"
                                  name="type"
                                  value="Fixed"
                                />
                                {t('fix')}
                              </label>
                              <label>
                                <Field
                                  className="form-control"
                                  type="radio"
                                  name="type"
                                  value="Bid"
                                />
                                {t('bid')}
                              </label>
                            </div>

                            <ErrorMessage
                              name="type"
                              component="div"
                              className="text-danger text-right"
                            />
                          </Form.Group>
                        </Col>
                      </Row>


                      <Row>
                        <Col md={(formRef?.current?.values?.type == 'Fixed' ? 12 : 6)}>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>{t('amount')}</Form.Label>
                            <Form.Text className="text-muted">
                              {t('enter_your_amount_in')} {configuration.get("configData.currency_code")}({configuration.get("configData.currency")})
                            </Form.Text>
                            <Field
                              className="form-control"
                              type="number"
                              placeholder="0"
                              name="amount"
                            />
                            <ErrorMessage
                              name="amount"
                              component="div"
                              className="text-danger text-right"
                            />
                          </Form.Group>
                        </Col>

                        {
                          formRef?.current?.values?.type == 'Bid' ?

                            <Col md={6}>

                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>{t('bid_closing_date')}</Form.Label>
                                <Form.Text className="text-muted">
                                  {t('enter_how_many_times_meet_up_occurrence')}
                                </Form.Text>
                                <Field
                                  className="form-control"
                                  type="date"
                                  name="bid_closed_at"
                                />
                                <ErrorMessage
                                  name="bid_closed_at"
                                  component="div"
                                  className="text-danger text-right"
                                />
                              </Form.Group>
                            </Col>

                            : ''
                        }

                      </Row>



                      <Row>
                        <Col md={6}>


                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>{t('who_can_see_your_meet_up')}</Form.Label>
                            <Form.Text className="text-muted">
                              {t('select_one_option_who_can_see_your_meetup')}
                            </Form.Text>
                            <Field
                              as="select"
                              name="privacy"
                              className="form-control"
                            >
                              <option value="Everyone">{t('everyone')}</option>
                              <option value="Private">{t('paid_users')}</option>
                            </Field>
                            <ErrorMessage
                              name="privacy"
                              component="div"
                              className="text-danger text-right"
                            />
                          </Form.Group>
                        </Col>


                        <Col md={6}>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>{t('location')}</Form.Label>
                            <Form.Text className="text-muted">
                              {t('enter_the_meet_up_location')}
                            </Form.Text>
                            <Field
                              className="form-control"
                              type="text"
                              placeholder={t('location')}
                              name="location"
                            />
                            <ErrorMessage
                              name="location"
                              component="div"
                              className="text-danger text-right"
                            />
                          </Form.Group>
                        </Col>
                      </Row>


                      <Row>

                        <Col md={6}>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>{t('date')}</Form.Label>
                            <Form.Text className="text-muted">
                              {t('select_date_of_meet_up')}
                            </Form.Text>
                            <Field
                              className="form-control"
                              type="date"
                              name="date"
                            />
                            <ErrorMessage
                              name="date"
                              component="div"
                              className="text-danger text-right"
                            />
                          </Form.Group>
                        </Col>


                        <Col md={6}>

                          <Form.Label>{t('time')}</Form.Label>
                          <Form.Text className="text-muted">
                            {t('select_time_of_meet_up')}
                          </Form.Text>

                          <Row>

                            <Col md={5}>

                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Field
                                  className="themeTimePicker"
                                  component={TimePicker}
                                  name="scheduled_at"
                                  ampm={false}
                                  openTo="hours"
                                  views={['hours', 'minutes', 'seconds']}
                                  format="HH:mm:ss"
                                />
                              </MuiPickersUtilsProvider>


                            </Col>
                            <Col md={2}>
                              <span className="date-med">{t('to')}</span>
                            </Col>

                            <Col md={5}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Field
                                  component={TimePicker}
                                  className="themeTimePicker"
                                  name="scheduled_end_at"
                                  ampm={false}
                                  openTo="hours"
                                  views={['hours', 'minutes', 'seconds']}
                                  format="HH:mm:ss"
                                />
                              </MuiPickersUtilsProvider>
                            </Col>

                          </Row>

                        </Col>
                      </Row>


                      <Row>
                        <Col md={12}>

                          <div className="settings-btn-sec">

                            <Button
                              type="submit"
                              className="settings-submit-btn"
                              disabled={
                                props.addMeetSchedule.buttonDisable
                              }
                            >
                              {props.addMeetSchedule.loadingButtonContent
                                ? props.addMeetSchedule.loadingButtonContent
                                : t('submit')
                              }
                            </Button>

                          </div>
                        </Col>
                      </Row>

                    </FORM>
                  )}
                </Formik>
              </div>


            </Col>
          </Row>

        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  categoryList: state.scheduleMeetup.categoryList,
  addMeetSchedule: state.scheduleMeetup.addMeetSchedule
});


function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(ScheduleMeetupAdd));
