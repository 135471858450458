
import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  FETCH_MEETUP_CATEGORY_LIST_START,
  ADD_MEETUP_SCHEDULE_START,
  FETCH_MEETUP_LIST_START,
  MEETUP_REQUEST_START,
  FETCH_ALL_MEETUP_REQUEST_START,
  FETCH_PUBLIC_REQUEST_START,
  FETCH_PUBLIC_MEETUP_SCHEDULES_START,
  SUBMIT_MEETUP_PRICE_START,
  SUBMIT_BOOK_MEETUP_SCHEDULE_CLEAR,
  SUBMIT_BOOK_MEETUP_SCHEDULE_START,
  SUBMIT_SCHEDULE_PAYMENT_BY_WALLET_START,
  FETCH_MEETUP_BOOKED_START,
  FETCH_MEETUP_SCHEDULE_BOOKED_START,
  SUBMIT_REQUEST_PAYMENT_BY_WALLET_START,

  SUBMIT_ACCEPT_BOOK_MEETUP_SCHEDULE_START,
  SUBMIT_RESCHEDULE_BOOKED_MEETUP_START,
  SUBMIT_RESCHEDULE_MEETUP_REQUEST_START,
  LOCALLY_UPDATE_MEETUP_SCHEDULE_BOOKED,
  SUBMIT_REQUEST_CANCEL_START,

} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";


import {
  fetchMeetupCategoryListSuccess,
  fetchMeetupCategoryListFailure,

  addMeetupScheduleSuccess,
  addMeetupScheduleFailure,

  fetchMeetupListSuccess,
  fetchMeetupListFailure,

  meetupRequestSuccess,
  meetupRequestFailure,

  fetchAllMeetupRequestSuccess,
  fetchAllMeetupRequestFailure,

  fetchPublicMeetupRequestSuccess,
  fetchPublicMeetupRequestFailure,

  fetchPublicMeetupScheduleSuccess,
  fetchPublicMeetupScheduleFailure,

  addMeetupPriceSuccess,
  addMeetupPriceFailure,

  submitBookMeetupScheduleSuccess,
  submitBookMeetupScheduleFailure,
  submitSchedulePaymentByWalletSuccess,
  submitSchedulePaymentByWalletFailure,


  fetchMeetupBookedSuccess,
  fetchMeetupBookedFailure,
  fetchMeetupScheduleBookedSuccess,
  fetchMeetupScheduleBookedFailure,

  submitRequestPaymentByWalletSuccess,
  submitRequestPaymentByWalletFailure,

  submitAcceptBookMeetupScheduleSuccess,
  submitAcceptBookMeetupScheduleFailure,
  submitRescheduleBookedMeetupSuccess,
  submitRescheduleBookedMeetupFailure,
  submitRescheduleMeetupRequestSuccess,
  submitRescheduleMeetupRequestFailure,
  submitRequestCancelSuccess,
  submitRequestCancelFailure

} from "../actions/ScheduleMeetupAction";

import {
  checkLogoutStatus,
} from "../actions/ErrorAction";





function* fetchMeetupCategoryListAPI() {
  try {
    const response = yield api.postMethod("view_meetup_categories");

    if (response.data.success) {
      yield put(fetchMeetupCategoryListSuccess(response.data.data));
    } else {
      yield put(fetchMeetupCategoryListFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchMeetupCategoryListFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* addMeetupScheduleAPI(action) {
  try {
    const response = yield api.postMethod("add_meetup_schedule", action.data);

    if (response.data.success) {
      yield put(addMeetupScheduleSuccess(response.data.data));

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      setTimeout(() => {
        window.location.assign("/schedule-meetup");
      }, 1000);

    } else {
      yield put(addMeetupScheduleFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(addMeetupScheduleFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}
function* fetchMeetupListAPI() {
  try {
    const response = yield api.postMethod("all_meetup_schedules");
    if (response.data.success) {
      yield put(fetchMeetupListSuccess(response.data.data));
    } else {
      yield put(fetchMeetupListFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchMeetupListFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


function* sendMeetupRequestAPI(action) {
  try {
    const response = yield api.postMethod("send_meetup_request", action.data);

    if (response.data.success) {
      yield put(meetupRequestSuccess(response.data.data));

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      setTimeout(() => {
        window.location.assign("/meetup-requests");
      }, 800);

    } else {
      yield put(meetupRequestFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(meetupRequestFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


function* fetchAllMeetupRequestsAPI() {
  try {
    const response = yield api.postMethod("all_meetup_requests");

    if (response.data.success) {
      yield put(fetchAllMeetupRequestSuccess(response.data.data));
    } else {
      yield put(fetchAllMeetupRequestFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchAllMeetupRequestFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


function* fetchPublicMeetupRequestAPI() {
  try {
    const response = yield api.postMethod("get_meetup_requests");

    if (response.data.success) {
      yield put(fetchPublicMeetupRequestSuccess(response.data.data));
    } else {
      yield put(fetchPublicMeetupRequestFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchPublicMeetupRequestFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchPublicMeetupScheduleAPI(action) {
  try {
    const response = yield api.postMethod("get_public_meetup_schedules", action.data);

    if (response.data.success) {
      yield put(fetchPublicMeetupScheduleSuccess(response.data.data));
    } else {
      yield put(fetchPublicMeetupScheduleFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchPublicMeetupScheduleFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


function* addMeetupPriceAPI(action) {

  try {

    const response = yield api.postMethod("submit_meetup_price", action.data);

    if (response.data.data) {
      yield put(addMeetupPriceSuccess(response.data.data));

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));

      action.data.props.data.requests.map((o) => {
        if (o.id == action.data.request_id) {
          o.amount = action.data.amount;
          o.status = response.data.data.status;
        }
        return o;
      });

      yield put(fetchPublicMeetupRequestSuccess(action.data.props.data));

    } else {
      yield put(addMeetupPriceFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(addMeetupPriceFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


function* clearBookMeetupScheduleAPI(action) {
  yield put(submitBookMeetupScheduleSuccess({}));
}

function* submitBookMeetupScheduleAPI(action) {
  try {
    const response = yield api.postMethod("book_meetup_schedule", action.data);

    if (response.data.success) {
      yield put(submitBookMeetupScheduleSuccess(response.data.data));

      // const notificationMessage = getSuccessNotificationMessage(
      //   response.data.message
      // );
      // yield put(createNotification(notificationMessage)); 

    } else {
      yield put(submitBookMeetupScheduleFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(submitBookMeetupScheduleFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* submitSchedulePaymentByWalletAPI(action) {
  try {
    const response = yield api.postMethod("schedule_payment_by_wallet", action.data);

    if (response.data.success) {
      yield put(submitSchedulePaymentByWalletSuccess(response.data.data));

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));


    } else {
      yield put(submitSchedulePaymentByWalletFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(submitSchedulePaymentByWalletFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


function* fetchMeetupBookedAPI(action) {
  try {
    const response = yield api.postMethod("meetup_booked", action.data);

    if (response.data.success) {
      yield put(fetchMeetupBookedSuccess(response.data.data));
    } else {
      yield put(fetchMeetupBookedFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchMeetupBookedFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* reMapScheduledBooking(action) {
  if (action && action.data && action.data.bookings && action.data.bookings.bookings && action.data.bookings.bookings.length > 0 && action.data.event && action.data.event.id) {
    action.data.bookings.bookings.map((o) => {
      if (o.id == action.data.event.id) {
        o.status = action.data.event.status;
      }
      return o;
    });
    yield put(fetchMeetupScheduleBookedSuccess(action.data.bookings));
  }
}

function* fetchMeetupScheduleBookedAPI(action) {
  try {
    const response = yield api.postMethod("meetup_schedule_booked", action.data);

    if (response.data.success) {
      yield put(fetchMeetupScheduleBookedSuccess(response.data.data));
    } else {
      yield put(fetchMeetupScheduleBookedFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchMeetupScheduleBookedFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* submitRequestPaymentByWalletAPI(action) {
  try {
    const response = yield api.postMethod("request_payment_by_wallet", action.data);

    if (response.data.success) {
      yield put(submitRequestPaymentByWalletSuccess(response.data.data));

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));


    } else {
      yield put(submitRequestPaymentByWalletFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(submitRequestPaymentByWalletFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


function* submitAcceptBookMeetupScheduleAPI(action) {
  try {
    const response = yield api.postMethod("accept_book_meetup_schedule", action.data);

    if (response.data.success) {
      yield put(submitAcceptBookMeetupScheduleSuccess(response.data.data));

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));


    } else {
      yield put(submitAcceptBookMeetupScheduleFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(submitAcceptBookMeetupScheduleFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* submitRescheduleBookedMeetupAPI(action) {
  try {
    const response = yield api.postMethod("reschedule_booked_meetup", action.data);

    if (response.data.success) {
      yield put(submitRescheduleBookedMeetupSuccess(response.data.data));

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));


    } else {
      yield put(submitRescheduleBookedMeetupFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(submitRescheduleBookedMeetupFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}
function* submitRescheduleMeetupRequestAPI(action) {
  try {
    const response = yield api.postMethod("reschedule_meetup_request", action.data);

    if (response.data.success) {
      yield put(submitRescheduleMeetupRequestSuccess(response.data.data));

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));


    } else {
      yield put(submitRescheduleMeetupRequestFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(submitRescheduleMeetupRequestFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


function* submitRequestCancelAPI(action) {
  try {
    const response = yield api.postMethod("cancel_meetup_request", action.data);

    if (response.data.success) {
      yield put(submitRequestCancelSuccess(response.data.data));

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));

      action.data.props.data.requests.map((o) => {
        if (o.id == action.data.request_id) {
          o.status = 'cancelled';
        }
        return o;
      });

      yield put(fetchPublicMeetupRequestSuccess(action.data.props.data));


    } else {
      yield put(submitRequestCancelFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(submitRequestCancelFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_MEETUP_CATEGORY_LIST_START, fetchMeetupCategoryListAPI)]);
  yield all([yield takeLatest(ADD_MEETUP_SCHEDULE_START, addMeetupScheduleAPI)]);
  yield all([yield takeLatest(FETCH_MEETUP_LIST_START, fetchMeetupListAPI)]);
  yield all([yield takeLatest(MEETUP_REQUEST_START, sendMeetupRequestAPI)]);
  yield all([yield takeLatest(FETCH_ALL_MEETUP_REQUEST_START, fetchAllMeetupRequestsAPI)]);
  yield all([yield takeLatest(FETCH_PUBLIC_REQUEST_START, fetchPublicMeetupRequestAPI)]);
  yield all([yield takeLatest(FETCH_PUBLIC_MEETUP_SCHEDULES_START, fetchPublicMeetupScheduleAPI)]);
  yield all([yield takeLatest(SUBMIT_MEETUP_PRICE_START, addMeetupPriceAPI)]);
  yield all([yield takeLatest(SUBMIT_BOOK_MEETUP_SCHEDULE_CLEAR, clearBookMeetupScheduleAPI)]);
  yield all([yield takeLatest(SUBMIT_BOOK_MEETUP_SCHEDULE_START, submitBookMeetupScheduleAPI)]);
  yield all([yield takeLatest(SUBMIT_SCHEDULE_PAYMENT_BY_WALLET_START, submitSchedulePaymentByWalletAPI)]);
  yield all([yield takeLatest(FETCH_MEETUP_BOOKED_START, fetchMeetupBookedAPI)]);
  yield all([yield takeLatest(FETCH_MEETUP_SCHEDULE_BOOKED_START, fetchMeetupScheduleBookedAPI)]);
  yield all([yield takeLatest(SUBMIT_REQUEST_PAYMENT_BY_WALLET_START, submitRequestPaymentByWalletAPI)]);
  yield all([yield takeLatest(SUBMIT_ACCEPT_BOOK_MEETUP_SCHEDULE_START, submitAcceptBookMeetupScheduleAPI)]);
  yield all([yield takeLatest(SUBMIT_RESCHEDULE_BOOKED_MEETUP_START, submitRescheduleBookedMeetupAPI)]);
  yield all([yield takeLatest(SUBMIT_RESCHEDULE_MEETUP_REQUEST_START, submitRescheduleMeetupRequestAPI)]);
  yield all([yield takeLatest(LOCALLY_UPDATE_MEETUP_SCHEDULE_BOOKED, reMapScheduledBooking)]);
  yield all([yield takeLatest(SUBMIT_REQUEST_CANCEL_START, submitRequestCancelAPI)]);
}
