import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Col, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";

const UserBookedMeetupNav = (props) => {
  return (
    <Col xs={12} sm={12} md={4}>
      <div className="vertical-menu">
        <div
          // activeClassName="active"
          className="bookmarkes-list back-button"
          // to={"/bookmarks"}
        >
          <Link to={"/home"}>
            <Image
              src={window.location.origin + "/assets/images/icons/back.svg"}
              className="svg-clone"
            />
          </Link>
         <span>{t("meet_up")}</span>
        </div>


        <NavLink
          activeClassName="active"
          className="bookmarkes-list"
          to={"/meetup-requests"}
        >
          <Image
            src="assets/images/schedule-meetup/request.svg"
            className="svg-clone my-p-icons"
          />
          <span>{t("your_requests")}</span>
        </NavLink>
        <NavLink
          activeClassName="active"
          className="bookmarkes-list"
          to={"/meetup-booking"}
        >
          <Image
            src="assets/images/schedule-meetup/confirm.svg"
            className="svg-clone my-p-icons"
          />
          <span>{t("meetup_booking")}</span>
        </NavLink>        

      </div>
    </Col>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(translate(UserBookedMeetupNav));
