import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import ScheduleMeetupkNav from "./ScheduleMeetupNav";
import './ScheduleMeetup.css';
import { connect } from "react-redux";
import ScheduleMeetupNoDataFound from "../NoDataFound/ScheduleMeetupNoDataFound";
import ScheduleMeetupLoader from "../Loader/ScheduleMeetupLoader";
import { translate, t } from "react-multi-lang";
import {
  fetchMeetupScheduleBookedStart,
  submitAcceptBookMeetupScheduleStart,
  locallyUpdateMeetupScheduleBooked
} from "../../store/actions/ScheduleMeetupAction";
import moment from 'moment';
import configuration from "react-global-configuration";
import RescheduleMeetupModal from "../Model/RescheduleMeetupModal";

const ScheduleMeetupConfirmed = (props) => {

  const [skip, setSkip] = useState({
    skip: 0,
    take: 1000,
  });
  const [reScheduleMeetupFlag, setReScheduleMeetupFlag] = useState(false);

  const [cickedButtonId, setCickedButtonId] = useState(0);

  useEffect(() => {
    props.dispatch(
      fetchMeetupScheduleBookedStart({ type: "all", skip: skip.skip, take: skip.take })
    );
  }, []);


  useEffect(() => {
    if (props.acceptBookMeetup && props.acceptBookMeetup.data && Object.keys(props.acceptBookMeetup.data) && Object.keys(props.acceptBookMeetup.data).length > 0) {
      setCickedButtonId(0)
      props.dispatch(
        locallyUpdateMeetupScheduleBooked({ bookings: props.scheduledMeetupBooking.data, event: props.acceptBookMeetup.data })
      );
    }
  }, [props.acceptBookMeetup]);


  const onClickRescedule = (obj) => {
    setReScheduleMeetupFlag(obj);
  }

  const closeResceduleMeetupModal = () => {
    setReScheduleMeetupFlag(false);
    props.dispatch(
      fetchMeetupScheduleBookedStart({ type: "all", skip: skip.skip, take: skip.take })
    );
  };


  const DateTimeFn = ({ date, format }) => {
    return (
      <>{moment(date).format(format)}</>
    )
  }


  const onClickAccept = (itemObj, payableAmount) => {

    let obj = { ...itemObj };

    setCickedButtonId(obj.id)

    let requestedData = {
      meet_up_booked_id: obj.id
    }

    props.dispatch(
      submitAcceptBookMeetupScheduleStart(requestedData)
    );
  }


  const StatusFn = ({ status }) => {

    let statusData = '';
    if (status == 'requested') {
      statusData = t('waiting_for_offer');
    }
    if (status == 'offer_sent') {
      statusData = t('waiting_for_payment');
    }
    if (status == 'offer_accepted') {
      statusData = t('offer_accepted');
    }
    if (status == 'confirmed') {
      statusData = t('confirmed');
    }
    if (status == 'cancelled') {
      statusData = t('cancelled');
    }
    if (status == 'accepted') {
      statusData = t('confirmed');
    }
    return (
      <>{statusData}</>
    )
  }

  return (
    <>
      <div className="edit-profile bookmark-sec">
        <Container>
          <Row>
            <ScheduleMeetupkNav />
            <Col sm={12} xs={12} md={8}>
              <div className="profile-post-area">
                <div className="bookmarkes-list bookmarks-right-side">
                  <div className="pull-left text-dark">
                    <h3>{t("meet_up_bookings")}</h3>
                  </div>
                </div>
              </div>
              {props.scheduledMeetupBooking.loading ? (
                <ScheduleMeetupLoader />
              ) : props.scheduledMeetupBooking.data.bookings.length > 0 ? (
                <Row className="margin-to-lg">
                  {props.scheduledMeetupBooking.data.bookings.map((post) =>
                    <Col md={12} key={post.id}>
                      <div className="star-box">
                        <div className="star-card-header">
                          <h3>{post.title ? post.title : post.meet_up_category}</h3>
                          <div className="star-card-price-wrapper">
                            <span className="star-card-price-label">
                              {post.type == 'Bid' ?
                                t("bid_amount")
                                : t("amount")
                              }
                            </span>
                            <span className="star-card-price">{configuration.get("configData.currency")}{post.amount}</span>
                          </div>
                        </div>

                        <div className="star-user-card">
                          <div className="star-user-img-sec">
                            <Image
                              className="star-user-img"
                              src={post.user_picture}
                            />
                          </div>
                          <div className="star-user-details">
                            <h5>{post.user}</h5>
                            <span className="user-tag">@{post.unique_id}</span>
                          </div>
                        </div>


                        <div className="star-card-flex-wrapper star-card-flex justify-space-between gap-10">

                          <div className="star-card-flex flex-direction-column gap-5">
                            <div className="star-card-flex justify-space-between gap-25">
                              <div className="star-card-date"> <DateTimeFn date={post.scheduled_at} format="dddd DD MMMM, YYYY" /></div>
                              <div className="star-card-time"> <DateTimeFn date={post.scheduled_at} format="hh:mm A" /> - <DateTimeFn date={post.scheduled_end_at} format="hh:mm A" /></div>
                            </div>
                            <div className="star-card-location">{post.location}</div>
                          </div>

                          <div className="star-card-flex gap-5 flex-direction-column align-items-center">

                            {
                              ['offer_sent', 'cancelled', 'confirmed', 'offer_accepted', 'accepted'].includes(post.status) ?

                                <span className={`star-card-status ${post.status}`}><StatusFn status={post.status} /></span>
                                : ''
                            }

                            {post.status == 'confirmed'
                              ?
                              <Button
                                type="submit"
                                className="theme-btn star-card-btn"
                                disabled={
                                  props.acceptBookMeetup.buttonDisable
                                }
                                onClick={() => onClickAccept(post)}
                              >
                                {cickedButtonId == post.id && props.acceptBookMeetup.loadingButtonContent
                                  ? props.acceptBookMeetup.loadingButtonContent
                                  : t("accept")
                                }
                              </Button>
                              : ''
                            }

                            {post.status == 'accepted'
                              ?
                              <Button type="button" className="theme-btn star-card-btn" onClick={() => onClickRescedule(post)}>
                                {t("reschedule")}
                              </Button>
                              : ''
                            }

                          </div>
                        </div>


                      </div>
                    </Col>
                  )}
                </Row>
              ) : (
                <ScheduleMeetupNoDataFound />
              )}
            </Col>
          </Row>
        </Container>
      </div>

      {reScheduleMeetupFlag && reScheduleMeetupFlag.id ? (
        <RescheduleMeetupModal
          event={reScheduleMeetupFlag}
          type='BOOKING'
          closeResceduleMeetupModal={closeResceduleMeetupModal}
        />
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  scheduledMeetupBooking: state.scheduleMeetup.scheduledMeetupBooking,
  acceptBookMeetup: state.scheduleMeetup.acceptBookMeetup
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(ScheduleMeetupConfirmed));
