import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import ScheduleMeetupkNav from "./ScheduleMeetupNav";
import './ScheduleMeetup.css';
import { connect } from "react-redux";
import ScheduleMeetupNoDataFound from "../NoDataFound/ScheduleMeetupNoDataFound";
import ScheduleMeetupLoader from "../Loader/ScheduleMeetupLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { translate, t } from "react-multi-lang";

import {
  fetchMeetupListStart
} from "../../store/actions/ScheduleMeetupAction";
import moment from 'moment';
import configuration from "react-global-configuration";

const ScheduleMeetupIndex = (props) => {

  const [skip, setSkip] = useState({
    skip: 0,
    take: 1000,
  });

  useEffect(() => {
    props.dispatch(
      fetchMeetupListStart({ type: "all", skip: skip.skip, take: skip.take })
    );
  }, []);


  const DateTimeFn = ({ date, format }) => {
    return (
      <>{moment(date).format(format)}</>
    )
  }

  return (
    <>
      <div className="edit-profile bookmark-sec">
        <Container>
          <Row>
            <ScheduleMeetupkNav />
            <Col sm={12} xs={12} md={8}>
              <div className="profile-post-area">
                <div className="bookmarkes-list bookmarks-right-side">
                  <div className="pull-left text-dark">
                    <h3>{t("schedules")}</h3>
                  </div>
                </div>
              </div>
              {props.meetupList.loading ? (
                <ScheduleMeetupLoader />
              ) : props.meetupList.data.schedules.length > 0 ? (
                <Row className="margin-to-lg">
                  {props.meetupList.data.schedules.map((post) =>
                    <Col md={12} key={post.id}>
                      <div className="star-box">
                        <div className="star-card-header">
                          <h3>{post.title ? post.title : post.meet_up_category}</h3>
                          <div className="star-card-price-wrapper">
                            <span className="star-card-price-label">
                              {post.type == 'Bid' ?
                                t("bid_starts_from")
                                : ''}
                            </span>
                            <span className="star-card-price">{configuration.get("configData.currency")}{post.amount}</span>
                          </div>
                        </div>

                        <br/>
                        <div className="star-card-flex-wrapper star-card-flex justify-space-between gap-10 align-items-end">

                          <div className="star-card-flex flex-direction-column gap-5">
                            <div className="book-star-card-date gap-img">
                              <Image src={window.location.origin + "/assets/images/schedule-meetup/eye-icon-light.svg"} />
                              {post.privacy}
                            </div>
                            <div className="book-star-card-date gap-img">
                              <Image src={window.location.origin + "/assets/images/schedule-meetup/cal-icon.svg"} />
                              <DateTimeFn date={post.scheduled_at} format="dddd DD MMMM, YYYY" />
                            </div>
                            <div className="book-star-card-time gap-img">
                              <Image src={window.location.origin + "/assets/images/schedule-meetup/time-icon.svg"} />
                              <DateTimeFn date={post.scheduled_at} format="hh:mm A" /> - <DateTimeFn date={post.scheduled_end_at} format="hh:mm A" />
                            </div>
                            <div className="book-star-card-location gap-img">
                              <Image src={window.location.origin + "/assets/images/schedule-meetup/location-icon.svg"} />
                              {post.location}
                            </div>
                          </div>

                          <div>
                            {/* <Button type="button" className="theme-btn" >
                              Repeat
                            </Button> */}
                          </div>

                        </div>                     


                      </div>
                    </Col>
                  )}
                </Row>
              ) : (
                <ScheduleMeetupNoDataFound />
              )}
            </Col>
          </Row>
        
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  meetupList: state.scheduleMeetup.meetupList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(ScheduleMeetupIndex));
