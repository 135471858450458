import React, { useState, useEffect } from 'react';
import {
  InputGroup,
  FormControl,
  Image,
  Modal,
  Media,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";
import AddCardModalSec from './AddCardModalSec';
import PaymentMethodCard from './PaymentMethodCard';
import { connect } from 'react-redux';
import { translate, t } from 'react-multi-lang';
import BookMeetupAmountModalSec from './BookMeetupAmountModalSec';

import { getErrorNotificationMessage } from '../../helper/NotificationMessage';
import { createNotification } from 'react-redux-notify';

import {
  submitSchedulePaymentByWalletStart,
  submitRequestPaymentByWalletStart
} from ".././../../store/actions/ScheduleMeetupAction";

const BookMeetupAmountModal = (props) => {
  console.log('props', props)

  const nullData = ["", null, undefined, "light"];
  const [paymentType, setPaymentType] = useState("WALLET");
  const [selectedCard, setSelectedCard] = useState(null);
  const [showAddCard, setShowAddCard] = useState(false);
  const [skipRender, setSkipRender] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (paymentType === "WALLET") {
      if (props.amount < 1) {
        props.dispatch(createNotification(getErrorNotificationMessage(t("add_wallet_min_amount"))));
      } else {
        setSkipRender(false);

        if(props.apiType == 'bid_payment'){
          props.dispatch(submitSchedulePaymentByWalletStart({
            meet_up_booked_id: props.submitedBookMeetupSchdule.id,
            amount: props.amount
          }));
        }

        if(props.apiType == 'request_payment'){
          props.dispatch(submitRequestPaymentByWalletStart({
            request_id: props.submitedBookMeetupSchdule.id,
            amount: props.amount
          }));
        }
       


      }
    }
  };

  const paypalOnSuccess = (payment) => {
    console.log('paypalOnSuccess', payment)
    // setTimeout(() => {
    //   props.dispatch(
    //     addMoneyViaPaypalStart({
    //       payment_id: payment.paymentID,
    //       amount: props.amount,
    //     })
    //   );
    // }, 1000);
    // props.dispatch(fetchWalletDetailsStart());
    // props.dispatch(fetchAllTransactionStart());
    // props.closepaymentsModal();
  };

  const paypalOnError = (err) => {
    const notificationMessage = getErrorNotificationMessage(err);
    this.props.dispatch(createNotification(notificationMessage));
  };

  const paypalOnCancel = (data) => {
    const notificationMessage = getErrorNotificationMessage(
      t("payment_cancelled_please_try_again")
    );
    this.props.dispatch(createNotification(notificationMessage));
  };


  useEffect(() => {
    if (!skipRender && !props.submitSchdulePaymentByWallet.loading && Object.keys(props.submitSchdulePaymentByWallet.data).length > 0) {
      props.closepaymentsModal();
    }
  }, [props.submitSchdulePaymentByWallet]);

  useEffect(() => {
    if (!skipRender && !props.submitRequestPaymentByWallet.loading && Object.keys(props.submitRequestPaymentByWallet.data).length > 0) {
      props.closepaymentsModal();
    }
  }, [props.submitRequestPaymentByWallet]);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    appearance,
  };

  return (
    <>
      <div className="payment-modal-sec">
        <Modal
          className={`modal-dialog-center user-list-free-modal payment-modal-res ${nullData.includes(localStorage.getItem("theme")) ?
            "" : "dark-theme-modal"
            }`}
          size="xl"
          centered
          show={props.paymentsModal}
          onHide={props.closepaymentsModal}
        >
          {/* <Modal.Header closeButton>
            {/* <Modal.Title>User List</Modal.Title> *
          </Modal.Header> */}
          <Modal.Body className="wallet-card-body">
            <Button className="modal-close"
              onClick={() => props.closepaymentsModal()}>
              <i className="fa fa-times" />
            </Button>
            <div className="payment-modal-body">
              <Row className="justify-content-between">
                <PaymentMethodCard
                  paymentType={paymentType}
                  setPaymentType={setPaymentType}
                  selectedCard={selectedCard}
                  setSelectedCard={setSelectedCard}
                  setShowAddCard={setShowAddCard}
                  tipAmount={props.amount}
                />
                <Col md={12} xl={5}>
                  <BookMeetupAmountModalSec
                    amount={props.amount}
                    paymentType={paymentType}
                    handleSubmit={handleSubmit}
                    paypalOnSuccess={paypalOnSuccess}
                    paypalOnError={paypalOnError}
                    paypalOnCancel={paypalOnCancel}
                    buttonDisable={(props.apiType == 'bid_payment')?props.submitSchdulePaymentByWallet.buttonDisable:props.submitRequestPaymentByWallet.buttonDisable}
                    loadingButtonContent={(props.apiType == 'bid_payment')?props.submitSchdulePaymentByWallet.loadingButtonContent:props.submitRequestPaymentByWallet.loadingButtonContent}
                  />
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

const mapStateToPros = (state) => ({
  // addAmount: state.wallet.addMoneyInput,
  wallet: state.wallet.walletData,
  submitSchdulePaymentByWallet: state.scheduleMeetup.submitSchdulePaymentByWallet,
  submitRequestPaymentByWallet: state.scheduleMeetup.submitRequestPaymentByWallet,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(BookMeetupAmountModal));