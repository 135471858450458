import React, { useState } from 'react';
import {
  Form,
  Button,
} from "react-bootstrap";
import configuration from "react-global-configuration";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { translate, t } from 'react-multi-lang';

const BookMeetupAmountModalSec = (props) => {

  let env = configuration.get("configData.PAYPAL_MODE"); // you can set here to 'production' for production
  let currency = "USD"; // or you can set this value from your props or state

  const client = {
    sandbox: configuration.get("configData.PAYPAL_ID"),
    production: configuration.get("configData.PAYPAL_ID"),
  };

  return (
    <>
      <div className="wallet-modal-details mt-5">
        <h4 className="payment-modal-title">
        Confirm Your Exclusive Meet-Up
          </h4>
        <p>          
        Don't miss the chance to meet a star person up close! Secure your booking by making a payment. Elevate your experience, engage in meaningful dialogue, and make your payment now to guarantee your spot in this extraordinary meet-up
        </p>
        <Form onSubmit={props.handleSubmit}>         
         
          <div className="wallet-account-balance mt-5 mb-1">
            {t("amount")} = {configuration.get("configData.currency")}{props.amount}<small>(included tax)</small>             
          </div>
          <div className="wallet-account-balance-token mb-5"> 
          {props.amount/configuration.get("configData.token_amount")} tokens are equivalent to the payable amount.   
          </div>
          <div className="add-card-btn">
            {props.paymentType === "PAYPAL" ?
              <PaypalExpressBtn
                env={env}
                client={client}
                currency={currency}
                total={props.amount}
                onError={props.paypalOnError}
                onSuccess={props.paypalOnSuccess}
                onCancel={props.paypalOnCancel}
              />
              : <Button type="submit"
                disabled={props.amount && props.amount > 0 ? false : true || props.buttonDisable}
              >{
                  props.loadingButtonContent ?
                    props.loadingButtonContent
                    : 'Confirm'
                }</Button>
            }
          </div>
        </Form>
      </div>
    </>
  )

}

export default translate(BookMeetupAmountModalSec);