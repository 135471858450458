import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import UserBookedMeetupNav from "./UserBookedMeetupNav";
import './UserBookedMeetup.css';
import { connect } from "react-redux";
import ScheduleMeetupNoDataFound from "../NoDataFound/ScheduleMeetupNoDataFound";
import ScheduleMeetupLoader from "../Loader/ScheduleMeetupLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { translate, t } from "react-multi-lang";
import BookMeetupAmountModal from "../Model/PaymentModal/BookMeetupAmountModal";
import {
  fetchAllMeetupRequestStart
} from "../../store/actions/ScheduleMeetupAction";
import moment from 'moment';
import configuration from "react-global-configuration";

const UserBookedMeetupIndex = (props) => {

  const [skip, setSkip] = useState({
    skip: 0,
    take: 1000,
  });

  const [paymentPopup, setPaymentPopup] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});

  useEffect(() => {
    props.dispatch(
      fetchAllMeetupRequestStart({ type: "all", skip: skip.skip, take: skip.take })
    );
  }, []);

  const DateTimeFn = ({ date, format }) => {
    return (
      <>{moment(date).format(format)}</>
    )
  }

  const StatusFn = ({ status }) => {

    let statusData = '';
    if (status == 'requested') {
      statusData = t('waiting_for_offer');
    }
    if (status == 'offer_sent') {
      statusData = t('waiting_for_payment');
    }
    if (status == 'offer_accepted') {
      statusData = t('confirmed');
    }
    if (status == 'confirmed') {
      statusData = t('confirmed');
    }
    if (status == 'cancelled') {
      statusData = t('cancelled');
    }
    return (
      <>{statusData}</>
    )
  }


  const onClickPaymentBtn = (dataObj) => {
    setSelectedEvent(dataObj);
    setPaymentPopup(true);
  }

  const closeAddWalletAmountModal = () => {
    setSelectedEvent({});
    props.dispatch(
      fetchAllMeetupRequestStart({ type: "all", skip: skip.skip, take: skip.take })
    );
    setPaymentPopup(false);

  };



  return (
    <>
      <div className="edit-profile bookmark-sec">
        <Container>
          <Row>
            <UserBookedMeetupNav />
            <Col sm={12} xs={12} md={8}>
              <div className="profile-post-area">
                <div className="bookmarkes-list bookmarks-right-side">
                  <div className="pull-left text-dark">
                    <h3>{t("meet_up_requests")}</h3>
                  </div>
                </div>
              </div>
              {props.meetupRequestList.loading ? (
                <ScheduleMeetupLoader />
              ) : props.meetupRequestList.data.requests.length > 0 ? (
                <Row className="margin-to-lg">
                  {props.meetupRequestList.data.requests.map((post) =>
                    <Col md={12} key={post.id}>
                      <div className="star-box">
                        <div className="star-card-header">
                          <h3>{post.title ? post.title : post.meet_up_category}</h3>
                          <div className="star-card-price-wrapper">
                            <span className={`star-card-status ${post.status}`}><StatusFn status={post.status} /></span>
                          </div>

                        </div>

                        <div className="star-user-card">
                          <div className="star-user-img-sec">
                            <Image
                              className="star-user-img"
                              src={post.influencer_picture}
                            />
                          </div>
                          <div className="star-user-details">
                            <h5>{post.influencer}</h5>
                            <span className="user-tag">@{post.unique_id}</span>
                          </div>
                        </div>


                        <div className="star-card-flex-wrapper star-card-flex justify-space-between gap-10">

                          <div className="star-card-flex flex-direction-column gap-5">
                            <div className="star-card-flex justify-space-between gap-25">
                              <div className="star-card-date"><DateTimeFn date={post.requested_at} format="dddd DD MMMM, YYYY" /></div>
                              <div className="star-card-time"><DateTimeFn date={post.requested_at} format="hh:mm A" /></div>
                            </div>
                            <div className="star-card-location">{post.location}</div>
                          </div>


                          {
                            ['offer_accepted', 'confirmed'].includes(post.status)
                              ?
                              <div className="star-card-price-wrapper align-items-end">
                                <span className="star-card-price-label">
                                  {post.type == 'Bid' ?
                                    t("bid_amount")
                                    : t("amount")
                                  }
                                </span>
                                <span className="star-card-price">{configuration.get("configData.currency")}{post.amount}</span>
                              </div>
                              : ''
                          }
                        </div>

                        {
                          post.status == 'offer_sent' ?
                            <div className="star-card-flex justify-space-between align-items-center gap-10">
                              <div className="requestd-amount-text">
                                {t("requested")}  <span className="requested-amount">{configuration.get("configData.currency")}{post.amount} <small>({t("EXCLUDED_TAX")})</small> </span> {t("for_this_meet_up")}
                              </div>
                              <div className="star-card-location">
                                <Button type="button" className="theme-btn" onClick={() => onClickPaymentBtn(post)}>
                                  {t("pay_now")}
                                </Button>
                              </div>
                            </div>
                            : ""
                        }

                      </div>
                    </Col>
                  )}
                </Row>
              ) : (
                <ScheduleMeetupNoDataFound />
              )}
            </Col>
          </Row>
        </Container >
      </div >

      {paymentPopup ?
        <BookMeetupAmountModal
          paymentsModal={paymentPopup}
          closepaymentsModal={closeAddWalletAmountModal}
          payments={props}
          submitedBookMeetupSchdule={selectedEvent}
          amount={selectedEvent.total}
          apiType={'request_payment'}
        />
        : null
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  meetupRequestList: state.scheduleMeetup.meetupRequestList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(UserBookedMeetupIndex));
