import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import ScheduleMeetupkNav from "./ScheduleMeetupNav";
import './ScheduleMeetup.css';
import { connect } from "react-redux";
import ScheduleMeetupNoDataFound from "../NoDataFound/ScheduleMeetupNoDataFound";
import ScheduleMeetupLoader from "../Loader/ScheduleMeetupLoader";
import { translate, t } from "react-multi-lang";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { createNotification } from "react-redux-notify";
import {
  getErrorNotificationMessage
} from "../../components/helper/NotificationMessage";

import {
  fetchPublicMeetupRequestStart,
  addMeetupPriceStart,
  submitRequestCancelStart
} from "../../store/actions/ScheduleMeetupAction";
import moment from 'moment';
import configuration from "react-global-configuration";
import RescheduleMeetupModal from "../Model/RescheduleMeetupModal";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-input': {
      padding: '15px 12px 15px !important'
    },
  },
}));


const ScheduleMeetupRequest = (props) => {

  const classes = useStyles();

  const [skip, setSkip] = useState({
    skip: 0,
    take: 1000,
  });

  const [amount, setAmount] = useState({});
  const [cickedButtonId, setCickedButtonId] = useState(0);
  const [reScheduleMeetupFlag, setReScheduleMeetupFlag] = useState(false);

  useEffect(() => {
    props.dispatch(
      fetchPublicMeetupRequestStart({ type: "all", skip: skip.skip, take: skip.take })
    );
  }, []);


  const handleInput = (inputEv, itemObj) => {
    const value = inputEv.target.value;
    if (value && Number(value) && Number(value) <= 0) {
      const notificationMessage = getErrorNotificationMessage(
        `${t("you_cannot_enter_amount_less_than")} 1`
      );
      props.dispatch(createNotification(notificationMessage))

      return;
    }
    amount[itemObj.id] = value;
    setSkip(amount);
  };


  const DateTimeFn = ({ date, format }) => {
    return (
      <>{moment(date).format(format)}</>
    )
  }
  const onSendOfferPrice = (postData) => {

    let amountData = amount[postData.id];
    if (amountData && Number(amountData) && Number(amountData) <= 0) {
      return;
    }

    let requestedData = {
      request_id: postData.id,
      amount: amountData,
      props: props.publicMeetupRequestList
    }
    props.dispatch(
      addMeetupPriceStart(requestedData)
    );

  }


  const onClickRescedule = (obj) => {
    setReScheduleMeetupFlag(obj);
  }

  const closeResceduleMeetupModal = () => {
    setReScheduleMeetupFlag(false);
    props.dispatch(
      fetchPublicMeetupRequestStart({ type: "all", skip: skip.skip, take: skip.take })
    );
  };
  const onClickCancel = (postData) => {

    let requestedData = {
      request_id: postData.id,
      props: props.publicMeetupRequestList
    }
    setCickedButtonId(postData.id);
    props.dispatch(
      submitRequestCancelStart(requestedData)
    );
  };


  const StatusFn = ({ status }) => {

    let statusData = '';
    if (status == 'requested') {
      statusData = t('waiting_for_offer');
    }
    if (status == 'offer_sent') {
      statusData = t('waiting_for_payment');
    }
    if (status == 'offer_accepted') {
      statusData = t('offer_accepted');
    }
    if (status == 'confirmed') {
      statusData = t('confirmed');
    }
    if (status == 'cancelled') {
      statusData = t('cancelled');
    }
    if (status == 'accepted') {
      statusData = t('confirmed');
    }
    return (
      <>{statusData}</>
    )
  }

  return (
    <>
      <div className="edit-profile bookmark-sec">
        <Container>
          <ScheduleMeetupkNav />
          <Col sm={12} xs={12} md={8}>
            <div className="profile-post-area">
              <div className="bookmarkes-list bookmarks-right-side">
                <div className="pull-left text-dark">
                  <h3>{t("meet_up_requests")}</h3>
                </div>
              </div>
            </div>
            {props.publicMeetupRequestList.loading ? (
              <ScheduleMeetupLoader />
            ) : props.publicMeetupRequestList.data.requests.length > 0 ? (
              <Row className="margin-to-lg">
                {props.publicMeetupRequestList.data.requests.map((post) =>
                  <Col md={12} key={post.id}>
                    <div className="star-box">
                      <div className="star-card-header">
                        <h3>{post.title ? post.title : post.meet_up_category}</h3>
                        <div className="star-card-price-wrapper align-items-end">

                          {
                            ['requested'].includes(post.status) || (post.amount && post.amount > 0) ?
                              <span className="star-card-price-label">{t("your_offer_for_this_meet_up")}</span>
                              : ''
                          }

                          <span className="star-card-price star-card-flex align-items-center gap-10 justify-content-center">
                            {
                              ['requested'].includes(post.status) ?
                                <>
                                  <TextField
                                    id="filled-number"
                                    placeholder="Price"
                                    type="number"
                                    min="1"
                                    variant="filled"
                                    className={classes.root}
                                    onChange={(e) => handleInput(e, post)}
                                  />

                                  <Button type="button" className="theme-btn star-card-btn place-offer-btn"
                                    disabled={
                                      props.addMeetupPrice.buttonDisable
                                    }

                                    onClick={() => onSendOfferPrice(post)} >

                                    {cickedButtonId == post.id && props.addMeetupPrice.loadingButtonContent
                                      ? props.addMeetupPrice.loadingButtonContent
                                      : t("place_offer")
                                    }

                                  </Button>
                                </>
                                : ''
                            }

                            {
                              post.amount && post.amount > 0 ?
                                configuration.get("configData.currency") + post.amount
                                : ''
                            }
                          </span>
                        </div>
                      </div>

                      <div className="star-user-card">
                        <div className="star-user-img-sec">
                          <Image
                            className="star-user-img"
                            src={post.user_picture}
                          />
                        </div>
                        <div className="star-user-details">
                          <h5>{post.user}</h5>
                          <span className="user-tag">@{post.unique_id}</span>
                        </div>
                      </div>


                      <div className="star-card-flex-wrapper star-card-flex justify-space-between gap-10">

                        <div className="star-card-flex flex-direction-column gap-5">
                          <div className="star-card-flex justify-space-between gap-25">
                            <div className="star-card-date"><DateTimeFn date={post.requested_at} format="dddd DD MMMM, YYYY" /></div>
                            <div className="star-card-time"><DateTimeFn date={post.requested_at} format="hh:mm A" /></div>
                          </div>
                          <div className="star-card-location">{post.location}</div>
                        </div>

                        <div className="star-card-flex gap-5 flex-direction-column align-items-end">

                          {
                            ['offer_sent', 'cancelled', 'confirmed', 'offer_accepted'].includes(post.status) ?

                              <span className={`star-card-status ${post.status}`}><StatusFn status={post.status} /></span>
                              : ''
                          }

                          {/* {
                            ['confirmed', 'offer_accepted'].includes(post.status) ?

                              <Button type="button" className="theme-btn star-card-btn" onClick={() => onClickRescedule(post)}>
                                Reschedule
                              </Button>
                              : ''
                          } */}
                          {
                            ['requested'].includes(post.status) ?

                              <Button type="button" className="theme-btn star-card-btn cancel-btn"
                                disabled={
                                  props.cancelRequestedMeetup.buttonDisable
                                }
                                onClick={() => onClickCancel(post)}
                              >

                                {cickedButtonId == post.id && props.cancelRequestedMeetup.loadingButtonContent
                                  ? props.cancelRequestedMeetup.loadingButtonContent
                                  : t("cancel_request")
                                }
                              </Button>
                              : ''
                          }
                        </div>
                      </div>


                    </div>
                  </Col>
                )}
              </Row>
            ) : (
              <ScheduleMeetupNoDataFound message="No requests found" />
            )}
          </Col>
        </Container>
      </div>

      {reScheduleMeetupFlag && reScheduleMeetupFlag.id ? (
        <RescheduleMeetupModal
          event={reScheduleMeetupFlag}
          type='REQUEST'
          closeResceduleMeetupModal={closeResceduleMeetupModal}
        />
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  publicMeetupRequestList: state.scheduleMeetup.publicMeetupRequestList,
  addMeetupPrice: state.scheduleMeetup.addMeetupPrice,
  cancelRequestedMeetup: state.scheduleMeetup.cancelRequestedMeetup,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(ScheduleMeetupRequest));
