import {
	FETCH_MEETUP_CATEGORY_LIST_START,
	FETCH_MEETUP_CATEGORY_LIST_SUCCESS,
	FETCH_MEETUP_CATEGORY_FAILURE,
	ADD_MEETUP_SCHEDULE_START,
	ADD_MEETUP_SCHEDULE_SUCCESS,
	ADD_MEETUP_SCHEDULE_FAILURE,
	FETCH_MEETUP_LIST_START,
	FETCH_MEETUP_LIST_SUCCESS,
	FETCH_MEETUP_LIST_FAILURE,
	MEETUP_REQUEST_START,
	MEETUP_REQUEST_SUCCESS,
	MEETUP_REQUEST_FAILURE,
	FETCH_ALL_MEETUP_REQUEST_START,
	FETCH_ALL_MEETUP_REQUEST_SUCCESS,
	FETCH_ALL_MEETUP_REQUEST_FAILURE,

	FETCH_PUBLIC_REQUEST_START,
	FETCH_PUBLIC_REQUEST_SUCCESS,
	FETCH_PUBLIC_REQUEST_FAILURE,

	FETCH_PUBLIC_MEETUP_SCHEDULES_START,
	FETCH_PUBLIC_MEETUP_SCHEDULES_SUCCESS,
	FETCH_PUBLIC_MEETUP_SCHEDULES_FAILURE,

	SUBMIT_MEETUP_PRICE_START,
	SUBMIT_MEETUP_PRICE_SUCCESS,
	SUBMIT_MEETUP_PRICE_FAILURE,

	SUBMIT_BOOK_MEETUP_SCHEDULE_CLEAR,
	SUBMIT_BOOK_MEETUP_SCHEDULE_START,
	SUBMIT_BOOK_MEETUP_SCHEDULE_SUCCESS,
	SUBMIT_BOOK_MEETUP_SCHEDULE_FAILURE,

	SUBMIT_SCHEDULE_PAYMENT_BY_WALLET_START,
	SUBMIT_SCHEDULE_PAYMENT_BY_WALLET_SUCCESS,
	SUBMIT_SCHEDULE_PAYMENT_BY_WALLET_FAILURE,

	FETCH_MEETUP_BOOKED_START,
	FETCH_MEETUP_BOOKED_SUCCESS,
	FETCH_MEETUP_BOOKED_FAILURE,

	LOCALLY_UPDATE_MEETUP_SCHEDULE_BOOKED,
	FETCH_MEETUP_SCHEDULE_BOOKED_START,
	FETCH_MEETUP_SCHEDULE_BOOKED_SUCCESS,
	FETCH_MEETUP_SCHEDULE_BOOKED_FAILURE,

	SUBMIT_REQUEST_PAYMENT_BY_WALLET_START,
	SUBMIT_REQUEST_PAYMENT_BY_WALLET_SUCCESS,
	SUBMIT_REQUEST_PAYMENT_BY_WALLET_FAILURE,


	SUBMIT_ACCEPT_BOOK_MEETUP_SCHEDULE_START,
	SUBMIT_ACCEPT_BOOK_MEETUP_SCHEDULE_SUCCESS,
	SUBMIT_ACCEPT_BOOK_MEETUP_SCHEDULE_FAILURE,

	SUBMIT_RESCHEDULE_BOOKED_MEETUP_START,
	SUBMIT_RESCHEDULE_BOOKED_MEETUP_SUCCESS,
	SUBMIT_RESCHEDULE_BOOKED_MEETUP_FAILURE,

	SUBMIT_RESCHEDULE_MEETUP_REQUEST_START,
	SUBMIT_RESCHEDULE_MEETUP_REQUEST_SUCCESS,
	SUBMIT_RESCHEDULE_MEETUP_REQUEST_FAILURE,

	SUBMIT_REQUEST_CANCEL_START,
	SUBMIT_REQUEST_CANCEL_SUCCESS,
	SUBMIT_REQUEST_CANCEL_FAILURE


} from '../actions/ActionConstant';



const initialState = {
	categoryList: {
		data: {},
		loading: true,
		error: false,
	},
	addMeetSchedule: {
		data: {},
		loading: true,
		error: false,
		loadingButtonContent: "",
		buttonDisable: false
	},
	meetupList: {
		data: {},
		loading: true,
		error: false,
	},
	meetupRequestSave: {
		data: {},
		loading: true,
		error: false,
		loadingButtonContent: "",
		buttonDisable: false
	},
	meetupRequestList: {
		data: {},
		loading: true,
		error: false,
	},
	publicMeetupRequestList: {
		data: {},
		loading: true,
		error: false,
	},
	publicMeetupScheduleList: {
		data: {},
		loading: true,
		error: false,
	},
	addMeetupPrice: {
		data: {},
		loading: true,
		error: false,
		loadingButtonContent: "",
		buttonDisable: false
	},
	submitBookMeetupSchdule: {
		data: {},
		loading: true,
		error: false,
		loadingButtonContent: "",
		buttonDisable: false
	},
	submitSchdulePaymentByWallet: {
		data: {},
		loading: true,
		error: false,
		loadingButtonContent: "",
		buttonDisable: false
	},
	myBookedMeetup: {
		data: {},
		loading: true,
		error: false,
	},
	scheduledMeetupBooking: {
		data: {},
		loading: true,
		error: false,
	},
	submitRequestPaymentByWallet: {
		data: {},
		loading: true,
		error: false,
		loadingButtonContent: "",
		buttonDisable: false
	},

	acceptBookMeetup: {
		data: {},
		loading: true,
		error: false,
		loadingButtonContent: "",
		buttonDisable: false
	},

	resceduleBookedMeetup: {
		data: {},
		loading: true,
		error: false,
		loadingButtonContent: "",
		buttonDisable: false
	},

	resceduleRequestedMeetup: {
		data: {},
		loading: true,
		error: false,
		loadingButtonContent: "",
		buttonDisable: false
	},
	cancelRequestedMeetup: {
		data: {},
		loading: true,
		error: false,
		loadingButtonContent: "",
		buttonDisable: false
	},

}

const ScheduleMeetupReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_MEETUP_CATEGORY_LIST_START:
			return {
				...state,
				categoryList: {
					data: {},
					loading: true,
					error: false,
				},
			};
		case FETCH_MEETUP_CATEGORY_LIST_SUCCESS:
			return {
				...state,
				categoryList: {
					data: action.data,
					loading: false,
					error: false,
				},
			};
		case FETCH_MEETUP_CATEGORY_FAILURE:
			return {
				...state,
				categoryList: {
					data: {},
					loading: false,
					error: action.error,
				},
			};
		case ADD_MEETUP_SCHEDULE_START:
			return {
				...state,
				addMeetSchedule: {
					data: {},
					loading: true,
					error: false,
					loadingButtonContent: "Please wait...",
					buttonDisable: true
				},
			};
		case ADD_MEETUP_SCHEDULE_SUCCESS:
			return {
				...state,
				addMeetSchedule: {
					data: action.data,
					loading: false,
					error: false,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};
		case ADD_MEETUP_SCHEDULE_FAILURE:
			return {
				...state,
				addMeetSchedule: {
					data: {},
					loading: false,
					error: action.error,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};

		case FETCH_MEETUP_LIST_START:
			return {
				...state,
				meetupList: {
					data: {},
					loading: true,
					error: false,
				},
			};
		case FETCH_MEETUP_LIST_SUCCESS:
			return {
				...state,
				meetupList: {
					data: action.data,
					loading: false,
					error: false,
				},
			};
		case FETCH_MEETUP_LIST_FAILURE:
			return {
				...state,
				meetupList: {
					data: {},
					loading: false,
					error: action.error,
				},
			};
		case MEETUP_REQUEST_START:
			return {
				...state,
				meetupRequestSave: {
					data: {},
					loading: true,
					error: false,
					loadingButtonContent: "Please wait...",
					buttonDisable: true
				},
			};
		case MEETUP_REQUEST_SUCCESS:
			return {
				...state,
				meetupRequestSave: {
					data: action.data,
					loading: false,
					error: false,
				},
			};
		case MEETUP_REQUEST_FAILURE:
			return {
				...state,
				meetupRequestSave: {
					data: {},
					loading: false,
					error: action.error,
				},
			};
		case FETCH_ALL_MEETUP_REQUEST_START:
			return {
				...state,
				meetupRequestList: {
					data: {},
					loading: true,
					error: false,
				},
			};
		case FETCH_ALL_MEETUP_REQUEST_SUCCESS:
			return {
				...state,
				meetupRequestList: {
					data: action.data,
					loading: false,
					error: false,
				},
			};
		case FETCH_ALL_MEETUP_REQUEST_FAILURE:
			return {
				...state,
				meetupRequestList: {
					data: {},
					loading: false,
					error: action.error,
				},
			};

		case FETCH_PUBLIC_REQUEST_START:
			return {
				...state,
				publicMeetupRequestList: {
					data: {},
					loading: true,
					error: false,
				},
			};
		case FETCH_PUBLIC_REQUEST_SUCCESS:
			return {
				...state,
				publicMeetupRequestList: {
					data: action.data,
					loading: false,
					error: false,
				},
			};
		case FETCH_PUBLIC_REQUEST_FAILURE:
			return {
				...state,
				publicMeetupRequestList: {
					data: {},
					loading: false,
					error: action.error,
				},
			};

		case FETCH_PUBLIC_MEETUP_SCHEDULES_START:
			return {
				...state,
				publicMeetupScheduleList: {
					data: {},
					loading: true,
					error: false,
				},
			};
		case FETCH_PUBLIC_MEETUP_SCHEDULES_SUCCESS:
			return {
				...state,
				publicMeetupScheduleList: {
					data: action.data,
					loading: false,
					error: false,
				},
			};
		case FETCH_PUBLIC_MEETUP_SCHEDULES_FAILURE:
			return {
				...state,
				publicMeetupScheduleList: {
					data: {},
					loading: false,
					error: action.error,
				},
			};
		case SUBMIT_MEETUP_PRICE_START:
			return {
				...state,
				addMeetupPrice: {
					data: {},
					loading: true,
					error: false,
				},
			};
		case SUBMIT_MEETUP_PRICE_SUCCESS:
			return {
				...state,
				addMeetupPrice: {
					data: action.data,
					loading: false,
					error: false,
				},
			};
		case SUBMIT_MEETUP_PRICE_FAILURE:
			return {
				...state,
				addMeetupPrice: {
					data: {},
					loading: false,
					error: action.error,
				},
			};

		case SUBMIT_BOOK_MEETUP_SCHEDULE_START:
			return {
				...state,
				submitBookMeetupSchdule: {
					data: {},
					loading: true,
					error: false,
					loadingButtonContent: "Please wait...",
					buttonDisable: true
				},
			};
		case SUBMIT_BOOK_MEETUP_SCHEDULE_SUCCESS:
			return {
				...state,
				submitBookMeetupSchdule: {
					data: action.data,
					loading: false,
					error: false,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};
		case SUBMIT_BOOK_MEETUP_SCHEDULE_FAILURE:
			return {
				...state,
				submitBookMeetupSchdule: {
					data: {},
					loading: false,
					error: action.error,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};
		case SUBMIT_BOOK_MEETUP_SCHEDULE_CLEAR:
			return {
				...state,
				submitBookMeetupSchdule: {
					data: {},
					loading: false,
					error: false,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};
		case SUBMIT_SCHEDULE_PAYMENT_BY_WALLET_START:
			return {
				...state,
				submitSchdulePaymentByWallet: {
					data: {},
					loading: true,
					error: false,
					loadingButtonContent: "Please wait...",
					buttonDisable: false
				},
			};
		case SUBMIT_SCHEDULE_PAYMENT_BY_WALLET_SUCCESS:
			return {
				...state,
				submitSchdulePaymentByWallet: {
					data: action.data,
					loading: false,
					error: false,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};
		case SUBMIT_SCHEDULE_PAYMENT_BY_WALLET_FAILURE:
			return {
				...state,
				submitSchdulePaymentByWallet: {
					data: {},
					loading: false,
					error: action.error,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};

		case FETCH_MEETUP_BOOKED_START:
			return {
				...state,
				myBookedMeetup: {
					data: {},
					loading: true,
					error: false,
				},
			};
		case FETCH_MEETUP_BOOKED_SUCCESS:
			return {
				...state,
				myBookedMeetup: {
					data: action.data,
					loading: false,
					error: false,
				},
			};
		case FETCH_MEETUP_BOOKED_FAILURE:
			return {
				...state,
				myBookedMeetup: {
					data: {},
					loading: false,
					error: action.error,
				},
			};

		case FETCH_MEETUP_SCHEDULE_BOOKED_START:
			return {
				...state,
				scheduledMeetupBooking: {
					data: {},
					loading: true,
					error: false,
				},
			};
		case LOCALLY_UPDATE_MEETUP_SCHEDULE_BOOKED:
			return {
				...state,
				scheduledMeetupBooking: {
					data: {},
					loading: true,
					error: false,
				},
			};
		case FETCH_MEETUP_SCHEDULE_BOOKED_SUCCESS:
			return {
				...state,
				scheduledMeetupBooking: {
					data: action.data,
					loading: false,
					error: false,
				},
			};
		case FETCH_MEETUP_SCHEDULE_BOOKED_FAILURE:
			return {
				...state,
				scheduledMeetupBooking: {
					data: {},
					loading: false,
					error: action.error,
				},
			};

		case SUBMIT_REQUEST_PAYMENT_BY_WALLET_START:
			return {
				...state,
				submitRequestPaymentByWallet: {
					data: {},
					loading: true,
					error: false,
					loadingButtonContent: "Please wait...",
					buttonDisable: false
				},
			};
		case SUBMIT_REQUEST_PAYMENT_BY_WALLET_SUCCESS:
			return {
				...state,
				submitRequestPaymentByWallet: {
					data: action.data,
					loading: false,
					error: false,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};
		case SUBMIT_REQUEST_PAYMENT_BY_WALLET_FAILURE:
			return {
				...state,
				submitRequestPaymentByWallet: {
					data: {},
					loading: false,
					error: action.error,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};

		case SUBMIT_ACCEPT_BOOK_MEETUP_SCHEDULE_START:
			return {
				...state,
				acceptBookMeetup: {
					data: {},
					loading: true,
					error: false,
					loadingButtonContent: "Please wait...",
					buttonDisable: false
				},
			};
		case SUBMIT_ACCEPT_BOOK_MEETUP_SCHEDULE_SUCCESS:
			return {
				...state,
				acceptBookMeetup: {
					data: action.data,
					loading: false,
					error: false,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};
		case SUBMIT_ACCEPT_BOOK_MEETUP_SCHEDULE_FAILURE:
			return {
				...state,
				acceptBookMeetup: {
					data: {},
					loading: false,
					error: action.error,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};

		case SUBMIT_RESCHEDULE_BOOKED_MEETUP_START:
			return {
				...state,
				resceduleBookedMeetup: {
					data: {},
					loading: true,
					error: false,
					loadingButtonContent: "Please wait...",
					buttonDisable: false
				},
			};
		case SUBMIT_RESCHEDULE_BOOKED_MEETUP_SUCCESS:
			return {
				...state,
				resceduleBookedMeetup: {
					data: action.data,
					loading: false,
					error: false,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};
		case SUBMIT_RESCHEDULE_BOOKED_MEETUP_FAILURE:
			return {
				...state,
				resceduleBookedMeetup: {
					data: {},
					loading: false,
					error: action.error,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};


		case SUBMIT_RESCHEDULE_MEETUP_REQUEST_START:
			return {
				...state,
				resceduleRequestedMeetup: {
					data: {},
					loading: true,
					error: false,
					loadingButtonContent: "Please wait...",
					buttonDisable: false
				},
			};
		case SUBMIT_RESCHEDULE_MEETUP_REQUEST_SUCCESS:
			return {
				...state,
				resceduleRequestedMeetup: {
					data: action.data,
					loading: false,
					error: false,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};
		case SUBMIT_RESCHEDULE_MEETUP_REQUEST_FAILURE:
			return {
				...state,
				resceduleRequestedMeetup: {
					data: {},
					loading: false,
					error: action.error,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};


		case SUBMIT_REQUEST_CANCEL_START:
			return {
				...state,
				cancelRequestedMeetup: {
					data: {},
					loading: true,
					error: false,
					loadingButtonContent: "Please wait...",
					buttonDisable: false
				},
			};
		case SUBMIT_REQUEST_CANCEL_SUCCESS:
			return {
				...state,
				cancelRequestedMeetup: {
					data: action.data,
					loading: false,
					error: false,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};
		case SUBMIT_REQUEST_CANCEL_FAILURE:
			return {
				...state,
				cancelRequestedMeetup: {
					data: {},
					loading: false,
					error: action.error,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};

		default:
			return state;
	}
}

export default ScheduleMeetupReducer;