import React from "react"
import Skeleton from "react-loading-skeleton";
import { Row, Col,Image } from "react-bootstrap";
const BookMeetupLoader = (props) => (
  <Row className="margin-to-lg">
    {
      [...Array(4)].map((o, i) =>

        <Col md={6} key={i}>
          <div className="star-box" >
            <div className="star-card-header">
              <h3><Skeleton className="mb-2" height={20} width={200} /></h3>
              <div className="star-card-price-wrapper">
                <span className="star-card-price-label">
                  <Skeleton className="mb-2" height={10} width={100} />
                </span>
                <span className="star-card-price">
                  <Skeleton className="mb-2" height={20} width={50} />
                </span>
              </div>
            </div>




            <div className="star-card-flex-wrapper star-card-flex justify-space-between gap-10 align-items-end">

              <div className="star-card-flex flex-direction-column gap-5">
                <div className="book-star-card-date gap-img">
                  <Image src={window.location.origin + "/assets/images/schedule-meetup/cal-icon.svg"} />
                  <Skeleton className="mb-2" height={10} width={150} />
                </div>
                <div className="book-star-card-time gap-img">
                  <Image src={window.location.origin + "/assets/images/schedule-meetup/time-icon.svg"} />
                  <Skeleton className="mb-2" height={10} width={100} />
                </div>
                <div className="book-star-card-location gap-img">
                  <Image src={window.location.origin + "/assets/images/schedule-meetup/location-icon.svg"} />
                  <Skeleton className="mb-2" height={10} width={100} />
                </div>
              </div>
            </div>

            <div className="star-card-flex-wrapper star-card-flex justify-space-between gap-10">

              <div className="star-card-flex flex-direction-column gap-5">
                <div className="star-card-flex justify-space-between gap-25">
                  <div className="star-card-date"><Skeleton className="mb-2" height={10} width={100} /></div>
                </div>             
              </div>

              <div>
                <Skeleton className="mb-2" height={30} width={70} />
              </div>
            </div>
          </div>

        </Col>


      )
    }

  </Row>
)

export default BookMeetupLoader;