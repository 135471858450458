import {
  FETCH_MEETUP_CATEGORY_LIST_START,
  FETCH_MEETUP_CATEGORY_LIST_SUCCESS,
  FETCH_MEETUP_CATEGORY_FAILURE,
  ADD_MEETUP_SCHEDULE_START,
  ADD_MEETUP_SCHEDULE_SUCCESS,
  ADD_MEETUP_SCHEDULE_FAILURE,
  FETCH_MEETUP_LIST_START,
  FETCH_MEETUP_LIST_SUCCESS,
  FETCH_MEETUP_LIST_FAILURE,
  MEETUP_REQUEST_START,
  MEETUP_REQUEST_SUCCESS,
  MEETUP_REQUEST_FAILURE,
  FETCH_ALL_MEETUP_REQUEST_START,
  FETCH_ALL_MEETUP_REQUEST_SUCCESS,
  FETCH_ALL_MEETUP_REQUEST_FAILURE,

  FETCH_PUBLIC_REQUEST_START,
  FETCH_PUBLIC_REQUEST_SUCCESS,
  FETCH_PUBLIC_REQUEST_FAILURE,

  FETCH_PUBLIC_MEETUP_SCHEDULES_START,
  FETCH_PUBLIC_MEETUP_SCHEDULES_SUCCESS,
  FETCH_PUBLIC_MEETUP_SCHEDULES_FAILURE,

  SUBMIT_MEETUP_PRICE_START,
  SUBMIT_MEETUP_PRICE_SUCCESS,
  SUBMIT_MEETUP_PRICE_FAILURE,

  SUBMIT_BOOK_MEETUP_SCHEDULE_CLEAR,
  SUBMIT_BOOK_MEETUP_SCHEDULE_START,
  SUBMIT_BOOK_MEETUP_SCHEDULE_SUCCESS,
  SUBMIT_BOOK_MEETUP_SCHEDULE_FAILURE,

  SUBMIT_SCHEDULE_PAYMENT_BY_WALLET_START,
  SUBMIT_SCHEDULE_PAYMENT_BY_WALLET_SUCCESS,
  SUBMIT_SCHEDULE_PAYMENT_BY_WALLET_FAILURE,

  FETCH_MEETUP_BOOKED_START,
  FETCH_MEETUP_BOOKED_SUCCESS,
  FETCH_MEETUP_BOOKED_FAILURE,

  LOCALLY_UPDATE_MEETUP_SCHEDULE_BOOKED,
  FETCH_MEETUP_SCHEDULE_BOOKED_START,
  FETCH_MEETUP_SCHEDULE_BOOKED_SUCCESS,
  FETCH_MEETUP_SCHEDULE_BOOKED_FAILURE,

  SUBMIT_REQUEST_PAYMENT_BY_WALLET_START,
  SUBMIT_REQUEST_PAYMENT_BY_WALLET_SUCCESS,
  SUBMIT_REQUEST_PAYMENT_BY_WALLET_FAILURE,


  SUBMIT_ACCEPT_BOOK_MEETUP_SCHEDULE_START,
  SUBMIT_ACCEPT_BOOK_MEETUP_SCHEDULE_SUCCESS,
  SUBMIT_ACCEPT_BOOK_MEETUP_SCHEDULE_FAILURE,

  SUBMIT_RESCHEDULE_BOOKED_MEETUP_START,
  SUBMIT_RESCHEDULE_BOOKED_MEETUP_SUCCESS,
  SUBMIT_RESCHEDULE_BOOKED_MEETUP_FAILURE,

  SUBMIT_RESCHEDULE_MEETUP_REQUEST_START,
  SUBMIT_RESCHEDULE_MEETUP_REQUEST_SUCCESS,
  SUBMIT_RESCHEDULE_MEETUP_REQUEST_FAILURE,

  SUBMIT_REQUEST_CANCEL_START,
	SUBMIT_REQUEST_CANCEL_SUCCESS,
	SUBMIT_REQUEST_CANCEL_FAILURE

} from './ActionConstant'


export function fetchMeetupCategoryListStart(data) {
  return {
    type: FETCH_MEETUP_CATEGORY_LIST_START,
    data
  };
}
export function fetchMeetupCategoryListSuccess(data) {
  return {
    type: FETCH_MEETUP_CATEGORY_LIST_SUCCESS,
    data
  };
}
export function fetchMeetupCategoryListFailure(error) {
  return {
    type: FETCH_MEETUP_CATEGORY_FAILURE,
    error
  };
}

export function addMeetupScheduleStart(data) {
  return {
    type: ADD_MEETUP_SCHEDULE_START,
    data
  };
}
export function addMeetupScheduleSuccess(data) {
  return {
    type: ADD_MEETUP_SCHEDULE_SUCCESS,
    data
  };
}
export function addMeetupScheduleFailure(error) {
  return {
    type: ADD_MEETUP_SCHEDULE_FAILURE,
    error
  };
}

export function fetchMeetupListStart(data) {
  return {
    type: FETCH_MEETUP_LIST_START,
    data
  };
}

export function fetchMeetupListSuccess(data) {
  return {
    type: FETCH_MEETUP_LIST_SUCCESS,
    data
  };
}

export function fetchMeetupListFailure(error) {
  return {
    type: FETCH_MEETUP_LIST_FAILURE,
    error
  };
}

export function meetupRequestStart(data) {
  return {
    type: MEETUP_REQUEST_START,
    data
  };
}
export function meetupRequestSuccess(data) {
  return {
    type: MEETUP_REQUEST_SUCCESS,
    data
  };
}
export function meetupRequestFailure(error) {
  return {
    type: MEETUP_REQUEST_FAILURE,
    error
  };
}

export function fetchAllMeetupRequestStart(data) {
  return {
    type: FETCH_ALL_MEETUP_REQUEST_START,
    data
  };
}
export function fetchAllMeetupRequestSuccess(data) {
  return {
    type: FETCH_ALL_MEETUP_REQUEST_SUCCESS,
    data
  };
}
export function fetchAllMeetupRequestFailure(error) {
  return {
    type: FETCH_ALL_MEETUP_REQUEST_FAILURE,
    error
  };
}


export function fetchPublicMeetupRequestStart(data) {
  return {
    type: FETCH_PUBLIC_REQUEST_START,
    data
  };
}
export function fetchPublicMeetupRequestSuccess(data) {
  return {
    type: FETCH_PUBLIC_REQUEST_SUCCESS,
    data
  };
}
export function fetchPublicMeetupRequestFailure(error) {
  return {
    type: FETCH_PUBLIC_REQUEST_FAILURE,
    error
  };
}

export function fetchPublicMeetupScheduleStart(data) {
  return {
    type: FETCH_PUBLIC_MEETUP_SCHEDULES_START,
    data
  };
}
export function fetchPublicMeetupScheduleSuccess(data) {
  return {
    type: FETCH_PUBLIC_MEETUP_SCHEDULES_SUCCESS,
    data
  };
}
export function fetchPublicMeetupScheduleFailure(error) {
  return {
    type: FETCH_PUBLIC_MEETUP_SCHEDULES_FAILURE,
    error
  };
}

export function addMeetupPriceStart(data) {
  return {
    type: SUBMIT_MEETUP_PRICE_START,
    data
  };
}
export function addMeetupPriceSuccess(data) {
  return {
    type: SUBMIT_MEETUP_PRICE_SUCCESS,
    data
  };
}
export function addMeetupPriceFailure(error) {
  return {
    type: SUBMIT_MEETUP_PRICE_FAILURE,
    error
  };
}


export function submitBookMeetupScheduleClear(data) {
  return {
    type: SUBMIT_BOOK_MEETUP_SCHEDULE_CLEAR,
    data
  };
}
export function submitBookMeetupScheduleStart(data) {
  return {
    type: SUBMIT_BOOK_MEETUP_SCHEDULE_START,
    data
  };
}
export function submitBookMeetupScheduleSuccess(data) {
  return {
    type: SUBMIT_BOOK_MEETUP_SCHEDULE_SUCCESS,
    data
  };
}
export function submitBookMeetupScheduleFailure(error) {
  return {
    type: SUBMIT_BOOK_MEETUP_SCHEDULE_FAILURE,
    error
  };
}

export function submitSchedulePaymentByWalletStart(data) {
  return {
    type: SUBMIT_SCHEDULE_PAYMENT_BY_WALLET_START,
    data
  };
}
export function submitSchedulePaymentByWalletSuccess(data) {
  return {
    type: SUBMIT_SCHEDULE_PAYMENT_BY_WALLET_SUCCESS,
    data
  };
}
export function submitSchedulePaymentByWalletFailure(error) {
  return {
    type: SUBMIT_SCHEDULE_PAYMENT_BY_WALLET_FAILURE,
    error
  };
}


export function fetchMeetupBookedStart(data) {
  return {
    type: FETCH_MEETUP_BOOKED_START,
    data
  };
}
export function fetchMeetupBookedSuccess(data) {
  return {
    type: FETCH_MEETUP_BOOKED_SUCCESS,
    data
  };
}
export function fetchMeetupBookedFailure(error) {
  return {
    type: FETCH_MEETUP_BOOKED_FAILURE,
    error
  };
}



export function locallyUpdateMeetupScheduleBooked(data) {
  return {
    type: LOCALLY_UPDATE_MEETUP_SCHEDULE_BOOKED,
    data
  };
}
export function fetchMeetupScheduleBookedStart(data) {
  return {
    type: FETCH_MEETUP_SCHEDULE_BOOKED_START,
    data
  };
}
export function fetchMeetupScheduleBookedSuccess(data) {
  return {
    type: FETCH_MEETUP_SCHEDULE_BOOKED_SUCCESS,
    data
  };
}
export function fetchMeetupScheduleBookedFailure(error) {
  return {
    type: FETCH_MEETUP_SCHEDULE_BOOKED_FAILURE,
    error
  };
}

export function submitRequestPaymentByWalletStart(data) {
  return {
    type: SUBMIT_REQUEST_PAYMENT_BY_WALLET_START,
    data
  };
}

export function submitRequestPaymentByWalletSuccess(data) {
  return {
    type: SUBMIT_REQUEST_PAYMENT_BY_WALLET_SUCCESS,
    data
  };
}
export function submitRequestPaymentByWalletFailure(error) {
  return {
    type: SUBMIT_REQUEST_PAYMENT_BY_WALLET_FAILURE,
    error
  };
}



export function submitAcceptBookMeetupScheduleStart(data) {
  return {
    type: SUBMIT_ACCEPT_BOOK_MEETUP_SCHEDULE_START,
    data
  };
}
export function submitAcceptBookMeetupScheduleSuccess(data) {
  return {
    type: SUBMIT_ACCEPT_BOOK_MEETUP_SCHEDULE_SUCCESS,
    data
  };
}
export function submitAcceptBookMeetupScheduleFailure(error) {
  return {
    type: SUBMIT_ACCEPT_BOOK_MEETUP_SCHEDULE_FAILURE,
    error
  };
}




export function submitRescheduleBookedMeetupStart(data) {
  return {
    type: SUBMIT_RESCHEDULE_BOOKED_MEETUP_START,
    data
  };
}
export function submitRescheduleBookedMeetupSuccess(data) {
  return {
    type: SUBMIT_RESCHEDULE_BOOKED_MEETUP_SUCCESS,
    data
  };
}
export function submitRescheduleBookedMeetupFailure(error) {
  return {
    type: SUBMIT_RESCHEDULE_BOOKED_MEETUP_FAILURE,
    error
  };
}



export function submitRescheduleMeetupRequestStart(data) {
  return {
    type: SUBMIT_RESCHEDULE_MEETUP_REQUEST_START,
    data
  };
}
export function submitRescheduleMeetupRequestSuccess(data) {
  return {
    type: SUBMIT_RESCHEDULE_MEETUP_REQUEST_SUCCESS,
    data
  };
}
export function submitRescheduleMeetupRequestFailure(error) {
  return {
    type: SUBMIT_RESCHEDULE_MEETUP_REQUEST_FAILURE,
    error
  };
}


export function submitRequestCancelStart(data) {
  return {
    type: SUBMIT_REQUEST_CANCEL_START,
    data
  };
  }
  export function submitRequestCancelSuccess(data) {
  return {
    type: SUBMIT_REQUEST_CANCEL_SUCCESS,
    data
  };
  }
  export function submitRequestCancelFailure(error) {
  return {
    type: SUBMIT_REQUEST_CANCEL_FAILURE,
    error
  };
  }