import React from "react"
import Skeleton from "react-loading-skeleton";
import { Row, Col } from "react-bootstrap";

const ScheduleMeetupLoader = (props) => (
  <Row className="margin-to-lg">
    {
      [...Array(2)].map((o, i) =>

      <Col md={12} key={i}>
        <div className="star-box" >
          <div className="star-card-header">
            <h3><Skeleton className="mb-2" height={20} width={200} /></h3>
            <div className="star-card-price-wrapper">
              <span className="star-card-price-label">
              <Skeleton className="mb-2" height={10} width={100} />
              </span>
              <span className="star-card-price">
              <Skeleton className="mb-2" height={20} width={50} />
              </span>
            </div>
          </div>

          <div className="star-user-card">
            <div className="star-user-img-sec">
            <Skeleton
              className="notification-sec-image-loader" />
            </div>
            <div className="star-user-details">
              <h5><Skeleton className="mb-2" height={10} width={100} /></h5>
              <span className="user-tag"><Skeleton className="mb-2" height={10} width={60} /></span>
            </div>
          </div>


          <div className="star-card-flex-wrapper star-card-flex justify-space-between gap-10">

            <div className="star-card-flex flex-direction-column gap-5">
              <div className="star-card-flex justify-space-between gap-25">
                <div className="star-card-date"><Skeleton className="mb-2" height={10} width={50} /></div>
                <div className="star-card-time"><Skeleton className="mb-2" height={10} width={50} /></div>
              </div>
              <div className="star-card-location"><Skeleton className="mb-2" height={10} width={70} /></div>
            </div>

            <div>
            <Skeleton className="mb-2" height={30} width={70} />
            </div>
          </div>
        </div>

      </Col>


      )
    }

  </Row>
)

export default ScheduleMeetupLoader;