import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import "./RequestMeetupModal.css";
import dayjs from "dayjs";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
  Media,
  Nav,
  Tab,
  InputGroup,
  FormControl,
  FormLabel,
} from "react-bootstrap";

import {
  submitRescheduleBookedMeetupStart,
  submitRescheduleMeetupRequestStart
} from "../../store/actions/ScheduleMeetupAction";

import moment from 'moment';

const RescheduleMeetupModal = (props) => {
  console.log('props', props)

  const nullData = ["", null, undefined, "light"];
  const [skipRender, setSkipRender] = useState(true);

  const formRef = useRef();


  useEffect(() => {

    if(props.type == 'BOOKING'){
      formRef.current.setFieldValue("meet_up_booked_id", props.event.id);
      formRef.current.setFieldValue("scheduled_at", moment(props.event.scheduled_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'));
      formRef.current.setFieldValue("start_time", moment(props.event.scheduled_at, 'YYYY-MM-DD HH:mm:ss').format('HH:mm'));
      formRef.current.setFieldValue("end_time", moment(props.event.scheduled_end_at, 'YYYY-MM-DD HH:mm:ss').format('HH:mm'));
    }

    if(props.type == 'REQUEST'){     
      formRef.current.setFieldValue("meet_up_booked_id", props.event.id);
      formRef.current.setFieldValue("scheduled_at", moment(props.event.requested_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'));
      formRef.current.setFieldValue("start_time", moment(props.event.requested_at, 'YYYY-MM-DD HH:mm:ss').format('HH:mm'));
    } 

   
  }, []);


  useEffect(() => {

    if (!skipRender && props.resceduleBookedMeetup && props.resceduleBookedMeetup.data && Object.keys(props.resceduleBookedMeetup.data).length > 0) {

      props.closeResceduleMeetupModal();
    }
  }, [props.resceduleBookedMeetup]);

  useEffect(() => {

    if (!skipRender && props.resceduleRequestedMeetup && props.resceduleRequestedMeetup.data && Object.keys(props.resceduleRequestedMeetup.data).length > 0) {

      props.closeResceduleMeetupModal();
    }
  }, [props.resceduleRequestedMeetup]);

  const handleClose = () => {
    props.closeResceduleMeetupModal();
  }

  const onSubmit = (value) => {
    console.log('value', value)

    let requestedData = {};

    if (props.type == 'BOOKING') {
      let date = moment(value.scheduled_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
      console.log('date', date)

      requestedData.meet_up_booked_id = value.meet_up_booked_id;
      requestedData.scheduled_at = `${date} ${value.start_time}:00`;
      requestedData.scheduled_end_at = `${date} ${value.end_time}:00`;
      console.log('requestedData', requestedData)
      setSkipRender(false);
      props.dispatch(submitRescheduleBookedMeetupStart(requestedData));

    }

    if (props.type == 'REQUEST') {

      let date = moment(value.scheduled_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
      console.log('date', date)

      requestedData.request_id = value.meet_up_booked_id;
      requestedData.requested_at = `${date} ${value.start_time}:00`;
      console.log('requestedData', requestedData)
      setSkipRender(false);
      props.dispatch(submitRescheduleMeetupRequestStart(requestedData));

    }
  }


  const initialValues = (type)=>{

    if(type == 'BOOKING'){
      return {
        scheduled_at: moment(props.event.scheduled_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
        start_time: moment(props.event.scheduled_at, 'YYYY-MM-DD HH:mm:ss').format('HH:mm'),
        end_time: moment(props.event.scheduled_end_at, 'YYYY-MM-DD HH:mm:ss').format('HH:mm'),
      }
    }

    if(type == 'REQUEST'){
      return {
        scheduled_at: moment(props.event.requested_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
        start_time: moment(props.event.requested_at, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
      }
    } 
  }



  return (
    <>
      <Modal
        className={`modal-dialog-center sent-tip-modal
        ${nullData.includes(localStorage.getItem("theme")) ?
            "" : "dark-theme-modal"
          }
        `}
        size="md"
        centered
        show={true}
        onHide={handleClose}
      >

        <div className="star-modal-header">

          <h3>Reschedule meet up date/time</h3>
          <Button className="star-modal-close" onClick={() => handleClose()}>
            <Image
              className="close-icon"
              src={
                window.location.origin + "/assets/images/schedule-meetup/close-white.svg"
              }
            />
          </Button>

        </div>


        {/* <div className="star-user-card margin-left-right">
          <div className="star-user-img-sec">
            <Image
              className="star-user-img"
              src={props.userDetails.data.user.picture}
              alt={props.userDetails.data.user.name}
            />
          </div>
          <div className="star-user-details">
            <h5>{props.userDetails.data.user.name}</h5>
            <span className="user-tag">@{props.userDetails.data.user.unique_id}</span>
          </div>
        </div> */}

        <div className="form-wrapper">
          <br />
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={initialValues(props.type)}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({ values, errors, touched }) => (
              <FORM className="edit-profile-form" noValidate>
                <Row>
                  <Col md={12}>
                    <Form.Label>Date</Form.Label>
                    <Form.Text className="text-muted">
                      Select date of Meet up
                    </Form.Text>
                  </Col>

                  <Col md={12}>

                    <Field
                      className="themeTimePicker"
                      name="scheduled_at"
                      type="date"
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12}>
                    <Form.Label>Time</Form.Label>
                    <Form.Text className="text-muted">
                      Select time of Meet up
                    </Form.Text>
                  </Col>

                  <Col md={(props.type == 'BOOKING' ? 6 : 12)}>
                    <Field
                      className="themeTimePicker"
                      name="start_time"
                      type="time"
                    />
                  </Col>

                  {props.type == 'BOOKING'
                    ?
                    <Col md={6}>
                      <Field
                        className="themeTimePicker"
                        name="end_time"
                        type="time"
                      />
                    </Col>
                    : ''
                  }
                </Row>




                <Row>
                  <Col md={12}>
                    <div className="settings-btn-sec">

                      <Button
                        type="submit"
                        className="settings-submit-btn"
                        disabled={
                          props.resceduleBookedMeetup.buttonDisable
                        }
                      >
                        {props.resceduleBookedMeetup.loadingButtonContent
                          ? props.resceduleBookedMeetup.loadingButtonContent
                          : 'Submit'
                        }
                      </Button>

                    </div>
                  </Col>
                </Row>



              </FORM>
            )}
          </Formik>

        </div>

      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  resceduleBookedMeetup: state.scheduleMeetup.resceduleBookedMeetup,
  resceduleRequestedMeetup: state.scheduleMeetup.resceduleRequestedMeetup
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(RescheduleMeetupModal));
