import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import './ScheduleMeetup.css';
import { connect } from "react-redux";
import { fetchBookmarksStart } from "../../store/actions/BookmarkAction";
import ScheduleMeetupNoDataFound from "../NoDataFound/ScheduleMeetupNoDataFound";
import BookMeetupLoader from "../Loader/BookMeetupLoader";
import { translate, t } from "react-multi-lang";
import { Link, NavLink } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import BookMeetupAmountModal from "../Model/PaymentModal/BookMeetupAmountModal";
import {
  fetchSingleUserProfileStart
} from "../../store/actions/OtherUserAction";
import { createNotification } from "react-redux-notify";
import {
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";

import {
  fetchPublicMeetupScheduleStart,
  submitBookMeetupScheduleStart,
  submitBookMeetupScheduleClear,
  
} from "../../store/actions/ScheduleMeetupAction";
import moment from 'moment';
import configuration from "react-global-configuration";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-input': {
      padding: '15px 12px 15px !important'
    },
    width: '100% !important'
  },
}));

const BookMeetup = (props) => {

  const classes = useStyles();

  const [skip, setSkip] = useState({
    skip: 0,
    take: 100,
  });
  const [selectedSlot, setSlot] = useState();
  const [bidPreview, setBidPreview] = useState(0);
  const [bidAmount, setBidAmount] = useState({});
  const [paymentPopup, setPaymentPopup] = useState(false);


  useEffect(() => {

    props.dispatch(
      fetchPublicMeetupScheduleStart({ type: "all", skip: skip.skip, take: skip.take, user_id: props.match.params.user_id, unique_id: props.match.params.unique_id, })
    );

    props.dispatch(
      fetchSingleUserProfileStart({
        user_unique_id: props.match.params.unique_id,
      })
    );
  }, []);

  useEffect(() => {
    if(props.submitBookMeetupSchdule && props.submitBookMeetupSchdule.data && props.submitBookMeetupSchdule.data.id){
      setBidPreview(1);   
    }    
  }, [props.submitBookMeetupSchdule]);


  const onClickPay = (itemObj, payableAmount) => {

    let obj = { ...itemObj };
    if (!payableAmount || (Number(payableAmount) && Number(payableAmount) <= 0)) {
      const notificationMessage = getErrorNotificationMessage(`${t('bid_amount_should_be_greater_than')} 0`);
      props.dispatch(createNotification(notificationMessage));
      return;
    }

    payableAmount = Number(payableAmount);
    obj.max_bid_amount = (obj.max_bid_amount) ? Number(obj.max_bid_amount) : 0
    obj.amount = (obj.amount) ? Number(obj.amount) : 0

    if (Number(obj.max_bid_amount) == 0) {
      if (payableAmount < obj.amount) {
        const notificationMessage = getErrorNotificationMessage(`${t('bid_amount_cannot_be_less_than')} ${obj.amount}`);
        props.dispatch(createNotification(notificationMessage));
        return;
      }
    }

    if (obj.max_bid_amount > 0) {
      if (payableAmount <= obj.max_bid_amount) {
        const notificationMessage = getErrorNotificationMessage(`${t('bid_amount_should_be_greater_than')} ${obj.max_bid_amount}`);
        props.dispatch(createNotification(notificationMessage));
        return;
      }
    }

    obj.payableAmount = payableAmount;
    setSlot(obj);


    let requestedData = {
      schedule_id: obj.id,
      amount: payableAmount
    }

    props.dispatch(
      submitBookMeetupScheduleStart(requestedData)
    );
  }


 

  const onClickBackToList = () => {
    setSlot(null);
    setBidPreview(0);
    setPaymentPopup(false);
    setBidAmount({});


  }
  const onClickPaymentBtn = () => {
    setPaymentPopup(true);
  }

  const closeAddWalletAmountModal = () => {
    props.dispatch(
      fetchPublicMeetupScheduleStart({ type: "all", skip: skip.skip, take: skip.take, user_id: props.match.params.user_id, unique_id: props.match.params.unique_id, })
    );    
    setSlot(null);
    setBidPreview(0);
    setPaymentPopup(false);
    setBidAmount({});

    props.dispatch(
      submitBookMeetupScheduleClear({})
    );    
  };

  const handleInput = (inputEv, itemObj) => {
    const value = inputEv.target.value;
    bidAmount[itemObj.id] = value;
    setBidAmount(bidAmount);
  };


  const DateTimeFn = ({ date, format }) => {
    return (
      <>{moment(date).format(format)}</>
    )
  }

  return (
    <>
      <div className="edit-profile bookmark-sec">
        <Container>
          {!selectedSlot ?
            <div className="star-page-header" >


              <div className="page-left-section">
                <Link to={"/home"}>
                  <Image
                    src={window.location.origin + "/assets/images/icons/back.svg"}
                    className="page-back-img"
                  />
                </Link>
                <span> {t('select_meet_up')}</span>
              </div>
              <div className="page-right-section">
              </div>
            </div>
            : ''

          }

          <Row className="margin-to-lg">          
            {props.influencerDetails.loading && props.publicMeetupScheduleList.loading ? (
              <BookMeetupLoader />
            ) : props.publicMeetupScheduleList.data.length > 0 ?
            bidPreview != 1?
                props.publicMeetupScheduleList.data.map((post) =>
                  <Col md={6} key={post.id}>
                    <div className="star-box">
                      <div className="star-card-header">
                        <h3>{post.title ? post.title : post.meet_up_category}</h3>
                        <div className="star-card-price-wrapper">
                          {(post.price_type == 'Bid') ? <span className="star-card-price-label">{t('bid_starts_from')}</span> : ''}
                          <span className="star-card-price">{configuration.get("configData.currency")}{post.amount}</span>
                        </div>
                      </div>

                      <div className="star-card-flex-wrapper star-card-flex justify-space-between gap-10 align-items-end">

                        <div className="star-card-flex flex-direction-column gap-5">
                          <div className="book-star-card-date gap-img">
                            <Image src={window.location.origin + "/assets/images/schedule-meetup/cal-icon.svg"} />
                            <DateTimeFn date={post.scheduled_at} format="dddd DD MMMM, YYYY" />
                          </div>
                          <div className="book-star-card-time gap-img">
                            <Image src={window.location.origin + "/assets/images/schedule-meetup/time-icon.svg"} />
                            <DateTimeFn date={post.scheduled_at} format="hh:mm A" /> - <DateTimeFn date={post.scheduled_end_at} format="hh:mm A" />
                          </div>
                          <div className="book-star-card-location gap-img">
                            <Image src={window.location.origin + "/assets/images/schedule-meetup/location-icon.svg"} />
                            {post.location}
                          </div>
                        </div>
                        {
                          (post.type == 'Fixed') ?
                            <div>
                          
                              <Button
                                type="submit"
                                className="settings-submit-btn"
                                onClick={() => onClickPay(post, post.amount)}
                                disabled={
                                  props.submitBookMeetupSchdule.buttonDisable
                                }
                              >
                                {selectedSlot && selectedSlot.id == post.id && props.submitBookMeetupSchdule.loadingButtonContent
                                  ? props.submitBookMeetupSchdule.loadingButtonContent
                                  : t('book')
                                }
                              </Button>


                            </div>
                            : ""
                        }
                      </div>


                      {(post.type == 'Bid') ?
                        <div className="bid-wrapper">

                          {
                            (post.max_bid_amount > 0)
                              ?
                              <>
                                <div className="bid-label-with-price">
                                  {t('current_maximum_bid_value')}  <span className="bid-amount">{configuration.get("configData.currency")}{post.max_bid_amount}</span>
                                </div>
                                <div className="bid-desc">{t('bidding_amount_should_be_more_than_maximum_bid_value')}</div>
                              </>
                              : ''
                          }

                          <div className="star-card-flex gap-10 align-items-center">
                            <div className="bid-input flex-1">
                              <TextField
                                id="filled-number"
                                placeholder="Price"
                                type="number"
                                variant="filled"
                                className={classes.root}
                                value={bidAmount[post.id]}
                                onChange={(e) => handleInput(e, post)}
                              />
                            </div>
                            <div className="bid-input">
                              <Button
                                type="submit"
                                className="settings-submit-btn"
                                onClick={() => onClickPay(post, bidAmount[post.id])}
                                disabled={
                                  props.submitBookMeetupSchdule.buttonDisable
                                }
                              >
                                {selectedSlot && selectedSlot.id == post.id && props.submitBookMeetupSchdule.loadingButtonContent
                                  ? props.submitBookMeetupSchdule.loadingButtonContent
                                  : t('place_bid')
                                }
                              </Button>


                            </div>
                          </div>
                        </div>
                        : ''}

                    </div>
                  </Col>
                )
                :

                <Col md={12} key={props.submitBookMeetupSchdule.data.id}>
                  <br />
                  <div className="star-box">

                    <div className="star-card--main-header">
                      <div className="card-left-section">
                        <a onClick={() => onClickBackToList()} className="cursor-pointer">
                          <Image
                            src={window.location.origin + "/assets/images/icons/back.svg"}
                            className="page-back-img"
                          />
                        </a>
                        <span> {t('book_a_meet_up')}</span>
                      </div>

                      <div className="card-right-section">
                        <Button type="button" className="theme-btn" onClick={() => onClickPaymentBtn()}>
                        {t('pay_now')}
                        </Button>
                      </div>

                    </div>

                    <Row className="margin-to-lg">
                      <Col md={6} >


                        <div className="star-user-card">
                          <div className="star-user-img-sec">
                            <Image
                              className="star-user-img"
                              src={props.influencerDetails.data.user.picture}
                            />
                          </div>
                          <div className="star-user-details">
                            <h5>{props.influencerDetails.data.user.name}</h5>
                            <span className="user-tag">@{props.influencerDetails.data.user.user_unique_id}</span>
                          </div>
                        </div>



                        <div className="star-card-header">
                          <h3>{selectedSlot && selectedSlot.title ? selectedSlot.title : selectedSlot.meet_up_category}</h3>
                        </div>

                        <div className="star-card-flex-wrapper star-card-flex justify-space-between gap-10 align-items-end">

                          <div className="star-card-flex flex-direction-column gap-5">
                            <div className="book-star-card-date gap-img">
                              <Image src={window.location.origin + "/assets/images/schedule-meetup/cal-icon.svg"} />
                              <DateTimeFn date={selectedSlot.scheduled_at} format="dddd DD MMMM, YYYY" />
                            </div>
                            <div className="book-star-card-time gap-img">
                              <Image src={window.location.origin + "/assets/images/schedule-meetup/time-icon.svg"} />
                              <DateTimeFn date={selectedSlot.scheduled_at} format="hh:mm A" /> - <DateTimeFn date={selectedSlot.scheduled_end_at} format="hh:mm A" />
                            </div>
                            <div className="book-star-card-location gap-img">
                              <Image src={window.location.origin + "/assets/images/schedule-meetup/location-icon.svg"} />
                              {selectedSlot.location}
                            </div>
                          </div>
                        </div>

                      </Col>
                      <Col md={1}>
                        <div className="vertical-seperator"></div>
                      </Col>
                      <Col md={5}>

                        <div className="star-card-flex justify-space-between">
                          <div className="final-bid-label">{t('bidding_amount')}</div>
                          <div className="final-bid-amount">{configuration.get("configData.currency")}{props.submitBookMeetupSchdule.data.amount}</div>
                        </div>
                        <div className="star-card-flex justify-space-between">
                          <div className="final-bid-label">{t('taxes')}</div>
                          <div className="final-bid-amount">{configuration.get("configData.currency")}{props.submitBookMeetupSchdule.data.tax_amount}</div>
                        </div>
                        <div className="star-card-flex justify-space-between star-border-top">
                          <div className="final-bid-label bold-text">{t('total')}</div>
                          {
                            props.submitBookMeetupSchdule.data.total
                              ? <div className="final-bid-amount bold-text">{configuration.get("configData.currency")}{props.submitBookMeetupSchdule.data.total}</div>
                              : <div className="final-bid-amount bold-text">{configuration.get("configData.currency")}0</div>
                          }
                        </div>

                      </Col>
                    </Row>
                  </div>
                </Col>
              : (<ScheduleMeetupNoDataFound />)
            }
          </Row>
        </Container>
      </div>

      {paymentPopup ?
        <BookMeetupAmountModal
          paymentsModal={paymentPopup}
          closepaymentsModal={closeAddWalletAmountModal}
          payments={props}
          submitedBookMeetupSchdule={props.submitBookMeetupSchdule.data}
          amount={props.submitBookMeetupSchdule.data.total}
          apiType={'bid_payment'}
        />
        : null
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  publicMeetupScheduleList: state.scheduleMeetup.publicMeetupScheduleList,
  influencerDetails: state.otherUser.userDetails,
  submitBookMeetupSchdule: state.scheduleMeetup.submitBookMeetupSchdule,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(BookMeetup));
