import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Col, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";

const ScheduleMeetupNav = (props) => {
  return (
    <Col xs={12} sm={12} md={4}>
      <div className="vertical-menu">
        <div
          // activeClassName="active"
          className="bookmarkes-list back-button"
        // to={"/bookmarks"}
        >
          <Link to={"/home"}>
            <Image
              src={window.location.origin + "/assets/images/icons/back.svg"}
              className="svg-clone"
            />
          </Link>
          <span>{t("schedule_a_meet_up")}</span>
        </div>


        <NavLink
          activeClassName="active"
          className="bookmarkes-list"
          to={"/add-meetup"}
        >
          <Image
            src="assets/images/schedule-meetup/add-meetup.svg"
            className="svg-clone my-p-icons"
          />
          <span>{t("add_schedule")}</span>
        </NavLink>
        <NavLink
          activeClassName="active"
          className="bookmarkes-list"
          to={"/schedule-meetup"}
        >
          <Image
            src="assets/images/schedule-meetup/schedule-meet.svg"
            className="svg-clone my-p-icons"
            style={{ width: '1.5em' }}
          />
          <span>{t("schedules")}</span>
        </NavLink>

        <NavLink
          activeClassName="active"
          className="bookmarkes-list"
          to={"/meetup-request"}
        >
          <Image
            src="assets/images/schedule-meetup/request.svg"
            className="svg-clone my-p-icons"
          />
          <span>{t("meet_up_requests")}</span>
        </NavLink>

        <NavLink
          activeClassName="active"
          className="bookmarkes-list"
          to={"/meetup-bookings"}
        >
          <Image
            src="assets/images/schedule-meetup/confirm.svg"
            className="svg-clone my-p-icons"
          />
          <span>{t("meet_up_bookings")}</span>
        </NavLink>
      </div>
    </Col>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(translate(ScheduleMeetupNav));
