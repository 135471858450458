import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import UserBookedMeetupNav from "./UserBookedMeetupNav";
import './UserBookedMeetup.css';
import { connect } from "react-redux";
import ScheduleMeetupNoDataFound from "../NoDataFound/ScheduleMeetupNoDataFound";
import ScheduleMeetupLoader from "../Loader/ScheduleMeetupLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { translate, t } from "react-multi-lang";

import {
  fetchMeetupBookedStart
} from "../../store/actions/ScheduleMeetupAction";
import moment from 'moment';
import configuration from "react-global-configuration";


const UserBooking = (props) => {

  const [skip, setSkip] = useState({
    skip: 0,
    take: 1000,
  });

  useEffect(() => {
    props.dispatch(
      fetchMeetupBookedStart({ type: "all", skip: skip.skip, take: skip.take })
    );
  }, []);

  const DateTimeFn = ({ date, format }) => {
    return (
      <>{moment(date).format(format)}</>
    )
  }

  const StatusFn = ({ status }) => {

    let statusData = '';
    if (status == 'requested') {
      statusData = t('waiting_for_offer');
    }
    if (status == 'pending') {
      statusData = t('pending');
    }
    if (status == 'offer_sent') {
      statusData = t('waiting_for_payment');
    }
    if (status == 'offer_accepted') {
      statusData = t('waiting_for_confirmation');
    }
    if (status == 'confirmed') {
      statusData = t('waiting_for_offer_accept');
    }
    if (status == 'cancelled') {
      statusData = t('cancelled');
    }
    if (status == 'accepted') {
      statusData = t('accepted');
    }
    return (
      <>{statusData}</>
    )
  }

  return (
    <>
      <div className="edit-profile bookmark-sec">
        <Container>
          <Row>
            <UserBookedMeetupNav />
            <Col sm={12} xs={12} md={8}>
              <div className="profile-post-area">
                <div className="bookmarkes-list bookmarks-right-side">
                  <div className="pull-left text-dark">
                    <h3>{t('meet_ups_booking')}</h3>
                  </div>
                </div>
              </div>
              {props.myBookedMeetup.loading ? (
                <ScheduleMeetupLoader />
              ) : props.myBookedMeetup.data.bookings.length > 0 ? (
                <Row className="margin-to-lg">
                  {props.myBookedMeetup.data.bookings.map((post) =>
                    <Col md={12} key={post.id}>
                      <div className="star-box">
                        <div className="star-card-header">
                          <h3>{post.meet_up_category} = {post.id}</h3>
                          <div className="star-card-price-wrapper">
                            <span className="star-card-price-label">
                              {post.type == 'Bid' ?
                                t('bid_amount')
                                : t('amount')
                              }
                            </span>
                            <span className="star-card-price">{configuration.get("configData.currency")}{post.amount}</span>
                          </div>

                        </div>

                        <div className="star-user-card">
                          <div className="star-user-img-sec">
                            <Image
                              className="star-user-img"
                              src={post.influencer_picture}
                            />
                          </div>
                          <div className="star-user-details">
                            <h5>{post.influencer}</h5>
                            <span className="user-tag">@{post.unique_id}</span>
                          </div>
                        </div>


                        <div className="star-card-flex-wrapper star-card-flex justify-space-between gap-10">

                          <div className="star-card-flex flex-direction-column gap-5">
                            <div className="star-card-flex justify-space-between gap-25">
                              <div className="star-card-date"><DateTimeFn date={post.requested_at} format="dddd DD MMMM, YYYY" /></div>
                              <div className="star-card-time"><DateTimeFn date={post.requested_at} format="hh:mm A" /></div>
                            </div>
                            <div className="star-card-location">{post.location}</div>
                          </div>

                          <span className={`star-card-status ${post.status}`}><StatusFn status={post.status} /></span>
                        </div>

                      </div>
                    </Col>
                  )}
                </Row>
              ) : (
                <ScheduleMeetupNoDataFound />
              )}
            </Col>
          </Row>
        </Container >
      </div >
    </>
  );
};

const mapStateToPros = (state) => ({
  myBookedMeetup: state.scheduleMeetup.myBookedMeetup,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(UserBooking));
